import { Box, FormControl } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import {usePermissions, useTranslate} from '../../customHooks';
import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Field } from "react-final-form";
import * as DataTypes from "../../api/FwaasDataTypes";
import { Toggle, Button as PANWDSButton } from "@panwds/react-ui";
import { PANTitle, SaveButton, PanSidePanel, toast } from '../../components';
import {
  Row
} from "../../components/FormElements";
import {PANWDSInput, PANWDSRadioField, PANWDSModal, PANWDSForm, PANWDSTooltip} from "../../components/PANWDSElements";
import { PANTile } from '../../components';
import { dataProvider } from "../../dataProvider";
import CheckCircle from '@material-ui/icons/CheckCircle';
import CircularLoader from '../../components/CircularLoader/CircularLoader';
import jwt_decode from "jwt-decode";
import * as EndPoints from "../../api/FwaasUriEndPoints";
import {useAppSelector} from "../../app/hooks";
import {checkCSPBanner} from "../../utils";

const useStyles = makeStyles((theme) => ({
  header: {
    border: '1px solid #DADBDB',
  },
  pantileContainer: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gap: 16,
    margin: 16,
  },
  tileContent: {
    display: 'grid',
    gridTemplateColumns: '300px calc(100% - 300px)',
    gridGap: 16,
    color: '#333333',
    '& div:nth-child(2n+1)': {
      color: '#707070'
    },
    boxSizing: 'border-box',
    fontSize: 12,
  },
  grayText: {
    paddingTop: '5px',
    color: '#707070'
  },
  backdrop: {
    position: "absolute",
    zIndex: 101,
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    overflow: "hidden",
  },
  checkIcon: {
    color: '#006FCC',
    fontSize: '18px',
    marginBottom: "-2px",
  },
  toolbar: {
    display: "flex",
    gap: theme.spacing(1),
    justifyContent: 'end',
    '-webkit-justify-content': 'flex-end',
    alignItems: 'start',
    padding: '10px 0',
    backgroundColor: 'white',
    borderTop: "1px solid #DADBDB",
  },
  loadingBlur: {
    filter: "blur(1px)",
  }
}));

const SidePanelForm = ({ isOpen, setOpen, auditInfo, saveAuditLog }: any) => {
  const translate = useTranslate();

  const SaveToolbar = (toolbarProps: any) => {
    const classes = useStyles();
    return (
      <div className={classes.toolbar}>
        <PANWDSButton
          size="md"
          appearance="secondary"
          onClick={() => setOpen(false)}
          dataMetrics="cloudngfw-tokens-edit-save"
        >
          Cancel
        </PANWDSButton>
        <SaveButton
          appearance="primary"
          size="md"
          label="Save"
          redirect={false}
          submitOnEnter={true}
          {...toolbarProps}
        />
      </div>
    );
  };
  const record = { ...auditInfo, EnableAuditTrail: String(auditInfo.EnableAuditTrail) };

  return (
    <PanSidePanel
      close={() => setOpen(false)}
      title={translate(`resources.tokens.auditLog.edit`)}
      open={isOpen}
    >
      <Box>
        <PANWDSForm
          toolbar={<SaveToolbar />}
          onSubmit={saveAuditLog}
          initialValues={record}
          style={{ padding: '16px' }}
        >
          <Row>
            <FormControl fullWidth>
              <b>{translate(`resources.tokens.auditLog.auditLog`)}</b>
              <Field
                name="EnableAuditTrail"
                // @ts-ignore
                component={PANWDSRadioField}
                options={[
                  { label: "None", value: 'false' },
                  { label: "CloudWatch", value: 'true' },
                ]}
                required
                dataMetrics={"cloudngfw-tenant-edit-audit-log"}
              />
            </FormControl>
          </Row>
          <Row>
            <FormControl fullWidth>
              <Field
                name="AuditTrail"
                // @ts-ignore
                component={PANWDSInput}
                title={translate(`resources.tokens.auditLog.group`)}
                required
                dataMetrics={"cloudngfw-tenant-edit-audit-log-arn"}
              />
            </FormControl>
          </Row>
        </PANWDSForm>
      </Box>
    </PanSidePanel>
  );
};

const TokensEdit = (props: any) => {
  const classes = useStyles();
  const translate = useTranslate();
    const reduxState = useAppSelector((state) => state);
    const { permissions } = usePermissions();
  const [updating, setUpdating] = useState(false);
  const [record, setRecord] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [supportInfo, setSupportInfo] = useState<any>({});
  //const [tenantInfo, setTenantInfo] = useState({ externalId: "", accountId: "", snsTopic: "" });
  const [toggleAuditLog, setToggleAuditLog] = useState(false);
  const [auditInfo, setAuditInfo] = useState<any>({
    AuditTrail: "", EnableAuditTrail: undefined
  });

    useEffect(() => {
        checkCSPBanner({translate, reduxState});
    }, [reduxState.support.cspEnable]);

  useEffect(() => {
    dataProvider.getList("tokens").then(async (response: any) => {
      if (response.data) {
        setRecord(response.data);
        pendoUpdate({programmaticAccess: response?.data?.Enabled})
      } else {
        toast.error(response?.error, { toastId: "tokens-getList" });
      }
    })
      .catch((e: any) => {
        toast.error(e?.error, { toastId: "tokens-getList" });
      }).finally(() => {
        setLoading(false);
      });

    dataProvider.describe("support").then(async (response: DataTypes.IFwaasApiResponse) => {
      if (response.data) {
        setSupportInfo({...response.data.SupportInformations[0], "ExternalId": response.data?.ExternalId} || {});
      } else {
        toast.error(response?.error?.error, { toastId: "support-describe" });
      }
    })
      .catch((e: any) => {
        toast.error(e?.error, { toastId: "support-describe" });
      });

    // dataProvider.getList("accounts").then(async (response: any) => {
    //   if (response.data) {
    //     const AccountDetails = response.data.AccountDetails;
    //     AccountDetails.map((account: DataTypes.Account) => {
    //       if (account?.AccountId) {
    //         let tenant: any = {
    //           externalId: account?.ExternalId ?? "",
    //           accountId: account?.ServiceAccountId ?? "",
    //           snsTopic: account?.SnsTopicArn ?? ""
    //         };
    //         setTenantInfo(tenant);
    //       }
    //     });
    //   }
    // }).catch((e: any) => {
    // });

    dataProvider.describe("settings")
      .then(async (response: DataTypes.IFwaasApiResponse) => {
        if (response.data) {
          setAuditInfo({
            AuditTrail: response.data.AuditTrail,
            EnableAuditTrail: response.data.EnableAuditTrail
          });
        }
      })
      .catch((e: any) => {
        toast.error(e?.error, { toastId: "settings-describe" });
      });
  }, []);

  const pendoUpdate = ({programmaticAccess}: any) => {
    const pendo = window.pendo;
    const idToken: any = localStorage.getItem('idToken');
    if (idToken) {
      const decodedToken: any = jwt_decode(idToken);
      if (decodedToken && decodedToken['email']) {
        const username: string = decodedToken["email"];
        if (username) {
          pendo.updateOptions({
            visitor: {
              id: username,
              tenantprogrammaticaccess: programmaticAccess
            },
          });
        }
      }
    }
  }

  const saveAuditLog = (info: any) => {
    let payload = { ...info, EnableAuditTrail: info.EnableAuditTrail === 'true' };
    //@ts-ignore
    dataProvider.update("settings", { payload: payload }).then(async (response: any) => {
      if (response.data) {
        setAuditInfo({
          AuditTrail: response.data.AuditTrail,
          EnableAuditTrail: response.data.EnableAuditTrail
        });
        setToggleAuditLog(false);
      } else {
        toast.error(response?.error, { toastId: "settings-update" });
      }
    }).catch((e: any) => {
      toast.error(e?.error?.error, { toastId: "settings-update" });
    });
  };

  const handleChange = (e: any) => {
    setOpenConfirm(!openConfirm);
  };

  const update = () => {
    setUpdating(true);
    //@ts-ignore
    dataProvider.update("tokens", { Enabled: !record?.Enabled })
      .then(async (response: any) => {
        pendoUpdate({programmaticAccess: !record?.Enabled});
        setRecord({ ...record, Enabled: !record?.Enabled });
        setOpenConfirm(false);
      })
      .catch((e: any) => {
        toast.error(e?.error, { toastId: "tokens-update" });
      }).finally(() => {
        setUpdating(false);
      })
  };

  const getEnableAudittrail = () => {
    if (auditInfo?.EnableAuditTrail === undefined) {
      return <>N/A</>
    }
    return (auditInfo?.EnableAuditTrail ?
      (<><CheckCircle className={classes.checkIcon} fontSize="small" /> {translate(`resources.tokens.auditLog.enabledCloudWatch`)}</>)
      : translate(`resources.tokens.auditLog.disabled`)
    );
  }

  return (
    <>
      <CircularLoader loading={loading} />
      <div className={loading ? classes.loadingBlur : ""}>
      <PANTitle divider title={translate(`resources.tokens.title`)} paddingContainer="16px"/>
      <div className={classes.pantileContainer}>

          <PANTile
              title={translate(`resources.tokens.generalInformation.title`)}
              size={12}
              titlePadding="8px 16px"
              padding="17px"
              divider
          >
              <div className={classes.tileContent}>
                  <div>{translate(`resources.tokens.generalInformation.name`)}</div>
                  <div>{supportInfo?.ExternalId}</div>
              </div>
          </PANTile>


        <PANTile
          title={translate(`resources.tokens.fields.access`)}
          size={12}
          titlePadding="12px 16px"
          padding="17px"
          divider
        >
          <div className={classes.tileContent}>
              <div>{translate(`resources.tokens.fields.access`)}</div>
              <div>
                  <PANWDSTooltip showTooltip={!reduxState.support.cspEnable} label={translate("csp.registerCSP")} >
                      <div style={{ display: 'flex', alignItems: 'center', width: "max-content" }}>
                          <Toggle checked={record?.Enabled}
                                  onClick={handleChange}
                                  disabled={isEmpty(record) || !(permissions.CreateProgrammaticAccess)}
                                  dataMetrics={"cloudngfw-tenant-edit-programmatic-access"}
                          />
                          <span style={{ paddingLeft: '6px' }}>{translate(record?.Enabled ? 'resources.tokens.values.enabled' : 'resources.tokens.values.disabled')}</span>
                      </div>
                  </PANWDSTooltip>
                  <div className={classes.grayText}>{translate('resources.tokens.values.accessSubtitle')}
                      <a target="_blank" href={"https://docs.paloaltonetworks.com/cloud-ngfw/aws/cloud-ngfw-on-aws/getting-started-with-cloud-ngfw-for-aws/enable-programmatic-access"}>{translate('resources.tokens.values.accessSubtitleDoc')}</a>
                  </div>
              </div>
          </div>
        </PANTile>

        <PANTile
          title={translate(`resources.tokens.auditLog.title`)}
          size={12}
          titlePadding="8px 16px"
          padding="17px"
          divider
          titleActions={
            <PANWDSButton
              size="md"
              appearance="secondary"
              disabled={updating}
              icon={<SettingsIcon fontSize="small" />}
              onClick={() => setToggleAuditLog(!toggleAuditLog)}
              dataMetrics="cloudngfw-tokens-edit-auditLog"
            >
              Edit
            </PANWDSButton>
          }
        >
          <div className={classes.tileContent}>
            <div>{translate(`resources.tokens.auditLog.auditLog`)}</div>
            <div>{getEnableAudittrail()}</div>
            <div>{translate(`resources.tokens.auditLog.group`)}</div>
            <div>{auditInfo?.AuditTrail}</div>
          </div>
          <SidePanelForm
            isOpen={toggleAuditLog}
            setOpen={setToggleAuditLog}
            auditInfo={auditInfo}
            saveAuditLog={saveAuditLog}
          />
        </PANTile>

        {supportInfo &&
          <PANTile
            title={translate(`resources.tokens.panels.support`)}
            size={12}
            titlePadding="12px 16px"
            padding="17px"
            divider
          >
            <div className={classes.tileContent}>
              <div>{translate(`resources.tokens.fields.serialNumber`)}</div>
              <div>{supportInfo?.SerialNumber}</div>
              <div>{translate(`resources.tokens.fields.supportAccount`)}</div>
              <div>{supportInfo?.SupportAccountId}</div>
              <div>{translate(`resources.tokens.fields.supportTenant`)}</div>
              <div>{supportInfo?.SupportTenantId}</div>
              <div>{translate(`resources.tokens.fields.supportType`)}</div>
              <div>{(supportInfo?.SupportType === EndPoints.SUPPORT_ENHANCED) ?  EndPoints.SUPPORT_PREMIUM : EndPoints.SUPPORT_STANDARD}</div>
            </div>
          </PANTile>
        }
      </div>
      </div>
      {/* <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <PANWDSModal
        title={translate(`resources.tokens.confirm.title`)}
        onClose={() => setOpenConfirm(false)}
        isOpen={openConfirm}
        size="sm"
        dataTestId="cloudngfw-tokens-edit-confirm-modal"
        dataMetrics="cloudngfw-tokens-edit-confirm-modal"
        modalBody={<div>{translate(`resources.tokens.confirm.description`)}</div>}
        modalBodyStylingRequired
        modalFooter={{
          footerRequired: true,
          cancelButtonRequired: true,
          featureSpecificButton: <SaveButton
            appearance="primary"
            size="md"
            label={translate(record?.Enabled ? 'resources.tokens.values.disable' : 'resources.tokens.values.enable')}
            redirect={false}
            onClick={() => update()}
            saving={updating}
            submitOnEnter={false}
            dataMetrics="cloudngfw-tokens-edit-confirm-modal-save"
            {...props}
          />
          }}
      />
    </>
  );
};
export default withRouter(TokensEdit);
