import {AccordionPanel} from "@panwds/react-ui";
import {usePermissions, useTranslate} from "../../../../customHooks";
import {Field} from "react-final-form";
import {PANWDSInput, PANWDSSelect, PANWDSSelectWithSearch} from "../../../../components/PANWDSElements";
import {FormControl, Grid} from "@material-ui/core";
import * as React from "react";
import {PANChip, Row} from "../../../../components/FormElements";
import {isEmpty} from "lodash";

interface GeneralProps {
    record: any,
}

const Index = (props: GeneralProps) => {
    const { record } = props;
    const translate = useTranslate();
    const { permissions } = usePermissions();
    return (
        <AccordionPanel title={translate(`resources.firewalls.fields.General`)}>
            <Row>
                <Grid item xs={12} sm={6} className="bottomPadding">
                    <FormControl fullWidth>
                        <Field
                            name="Firewall.FirewallName"
                            readOnly
                            // @ts-ignore
                            component={PANWDSInput}
                            title={translate(`resources.firewalls.fields.FirewallName`)}
                            dataMetrics={"cloudngfw-firewall-edit-name"}
                        />
                    </FormControl>
                </Grid>
            </Row>
            <Row>
                <Grid item xs={12} sm={6} className="bottomPadding">
                    <FormControl fullWidth>
                        <Field
                            name="Firewall.Description"
                            // @ts-ignore
                            component={PANWDSInput}
                            title={translate(`resources.firewalls.fields.Description`)}
                            disabled={!permissions?.UpdateFirewallDescription}
                            dataMetrics={"cloudngfw-firewall-edit-description"}
                        />
                    </FormControl>
                </Grid>
            </Row>
            <Row>
                <Grid item xs={12} sm={6} className="bottomPadding">
                    <FormControl fullWidth>
                        <Field
                            name="Firewall.Tags"
                            // @ts-ignore
                            component={PANChip}
                            options={[]}
                            addNew
                            addNewLabel="Add new"
                            label={translate(`resources.firewalls.fields.Tags`)}
                            addDirectly
                            disabled={!permissions?.UnTagFirewall}
                            disableButton={!permissions?.TagFirewall}
                        />
                    </FormControl>
                </Grid>
            </Row>
            <Row>
                <Grid item xs={12} sm={6} className="bottomPadding">
                    <FormControl fullWidth>
                        <Field
                            name="Firewall.AccountId"
                            readOnly
                            // @ts-ignore
                            component={PANWDSSelect}
                            items={(record?.Firewall?.AccountId) ? [{ text: record?.Firewall?.AccountId, value: record?.Firewall?.AccountId }] : []}
                            title={translate(`resources.firewalls.fields.AccountId`)}
                            required={false}
                            loading={isEmpty(record)}
                            dataMetrics={"cloudngfw-firewall-edit-account-id"}
                            placeholder={'Select an account'}
                        />
                    </FormControl>
                </Grid>
            </Row>
            <Row>
                <Grid item xs={12} sm={6} className="bottomPadding">
                    <FormControl fullWidth>
                        <Field
                            name="Firewall.VpcId"
                            readOnly
                            // @ts-ignore
                            component={PANWDSSelectWithSearch}
                            items={(record?.Firewall?.VpcId) ? [{ text: record?.Firewall?.VpcName ? `${record?.Firewall?.VpcId} (${record?.Firewall?.VpcName})` : record?.Firewall?.VpcId, value: record?.Firewall?.VpcId }] : []}
                            title={translate(`resources.firewalls.fields.VpcId`)}
                            required={false}
                            loading={isEmpty(record)}
                            dataMetrics={"cloudngfw-firewall-edit-vpc"}
                        />
                    </FormControl>
                </Grid>
            </Row>
        </AccordionPanel>
    );
};

export default Index;
