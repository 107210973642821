import { makeStyles} from '@material-ui/core';
import { FilterBar, useFilters } from "@panwds/react-filters";
import { Tooltip }  from "@panwds/react-ui";
import { InfoIcon } from '@panwds/icons';
import { TableProvider, ConnectedTable, ConnectedTableLayout, ConnectedGroupBySelect } from '@panwds/react-table';
import { FC, useState, useEffect, useRef, useLayoutEffect } from 'react';
import EmptyListImage from '../../images/empty_firewall.svg';
import {IDropDownActions, IFiltersConfig, IPANSearchbar, ISearchBarFilters} from '../../types';
import './PANWDSTable.css';
import _, { memoize } from 'lodash';
import { searchGridDataFilter } from '../../utils/search';
import { TableToolBar } from './TableToolBar';

const useStyles = makeStyles(() => ({
  infiniteScrollTableBox: (props:any) => ({
    padding: 16,
    overflowX: 'auto',
    '& div.overflow-table-container > div div.tw-flex-grow.tw-overflow-auto': {
      height: `${props.offsetTableHeight ? 'calc(100vh - ' + props.offsetTableHeight + 'px)' : '100vh'}`
    }
  }),
  styleFiletrsCointainer: {
    borderBottom: '2px solid #e4e3e2',
  },
  customTableBox: (props:any) => ({
    ...props.tableContainerStyle
  }),
  iconedParagraph: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '5px',
    gap: '10px',
    color: "#333333",
  }
}));

export interface CustomStyleProps {
  display?: string,
  flexFlow?: string,
  padding?: string,
  boxSizing?: string,
  height?: string,
  overflowX?: string,
  overflowY?: string
}

export interface PANWDSTableProps {
  gridData: never[],
  columns: any,
  title: string,
  dataMetrics?: string,
  loadGridData?: any,
  offsetTableHeight?: any,
  infiniteScroll?: boolean,
  subtitle?: string,
  loading?: boolean,
  multiSelect?: boolean,
  singleSelect?: boolean,
  sortable?: boolean,
  resizable?: boolean,
  pagination?: boolean,

  showToolbar?: boolean,
  dropDownActionsArray?: IDropDownActions[],

  showTileTitle?: boolean;
  emptyTitle?: string,
  emptySubtitle?: string,
  groupBy?: string[],
  lockedRows?: any[],
  dataTestId: string,
  searchBar?: IPANSearchbar,
  rowSize?: 'cozy' | 'standard' | 'comfy',
  filterBar?: ISearchBarFilters;
  searchFilterRequired?: boolean
  filterBarRequired?: boolean;
  filterConfig?: IFiltersConfig[];
  enablePagination?: boolean
  isBackgroundFetching?: any
  showSelectGroupBy?: boolean,
  initialGroupBy?: string[],
  tableContainerStyle?: CustomStyleProps,
  tableInnerContainerClassName?: string,
  overflow?: boolean,
  overflowTable?: boolean,
  disableRow?: Function,
  disableActionsBtn?: boolean | Function,
}

 const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  let doit: any;
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', () => {
      clearTimeout(doit);
      doit = setTimeout(updateSize, 800);
    });
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export const PanwdsTableLightWithFilters: FC<PANWDSTableProps> = ({
  searchFilterRequired=false,
  ...props
}) => {
  const [width, height] = useWindowSize();
  const classes = useStyles({ tableContainerStyle: props.tableContainerStyle, offsetTableHeight: props.offsetTableHeight});
  const tableInstanceRef = useRef<any>();
  const { filterBar } = props;
  const { filters } = useFilters('FilterAndTable');
  const [classNameForEmpty, setClassNameForEmpty] =  useState(false);
  const [tableKey, setTableKey] = useState(0);

  // default these props to true
  let isMultiselect = props.multiSelect === undefined || props.multiSelect === true;
  let isSortable = props.sortable === undefined || props.sortable === true;
  let isResizable = props.resizable === undefined || props.resizable === true;
  let loading = props.loading;
  let showToolbar = props.showToolbar;
  
  const applyFilters = (row: any) => {
      // if filters are empty
      if (
          Object.values(filters).every(
              (filter: any) => filter === undefined || filter?.length === 0
          )
      ) {
          return true;
      }

      const filterResult = [];
      for (const filterProp in filters) {
          if (filters[filterProp]?.length > 0) {
              let columnProperty = "";
              for (var i = 0; i < filterBar?.filterConfig?.length; i++) {
                  if (filterBar?.filterConfig[i]?.name == filterProp) {
                      columnProperty = filterBar?.filterConfig[i]?.columnValue;
                      break;
                  }
              }

              if (columnProperty?.includes(".")) {
                  let prop = columnProperty?.split(".");
                  // if loop for tags and characteristic where we have comma separated values for column
                  if (Array.isArray(row[prop[0]][prop[1]])) {
                      let match = false;
                      for (const val of row[prop[0]][prop[1]]) {
                          if (filters[filterProp]?.includes(val)) {
                              match = true;
                          }
                      }
                      if (match) {
                          filterResult.push(true);
                      } else {
                          return false;
                      }
                  } else {
                      filterResult.push(
                          filters[filterProp]?.includes(row[prop[0]][prop[1]])
                      );
                  }
              } else {
                  if (columnProperty === "Characteristics") {
                      let newval = displayOptions(row[columnProperty]);
                      filterResult.push(
                          filters[filterProp].some((r: any) =>
                              newval.includes(r)
                          )
                      );
                  } else {
                      // if loop for tags and characteristic where we have comma separated values for column
                      if (Array.isArray(row[columnProperty])) {
                          let match = false;
                          for (const val of row[columnProperty]) {
                              if (filters[filterProp]?.includes(val)) {
                                  match = true;
                                  filterResult.push(true);
                              }
                          }
                          if (match) {
                              filterResult.push(true);
                          } else {
                              return false;
                          }
                      } else {
                          filterResult.push(
                              filterResult.push(
                                  filters[filterProp]?.includes(
                                      row[columnProperty]
                                  )
                              )
                          );
                      }
                  }
              }
          }
      }
      return filterResult.every((res) => res);
  };

  useEffect(() => {
      // force rerender since the column resizing is handled in our code.
      setTableKey(tableKey + 1);
  }, [width, height]);

  const displayOptions = (filterOption: any) => {
      let newRowsObj = [];

      for (var key in filterOption) {
          if (key === "DenyAction" && filterOption[key] !== "") {
              newRowsObj.push(key);
          } else if (filterOption[key] === true) newRowsObj.push(key);
      }
      return newRowsObj;
  };

  const onColumnWidthChange = memoize((columnWidths: { [key: string]: number }) => {
    // dataTestId is required, will be used as key in the local storage
    if (props.dataTestId && Object.keys(columnWidths).length) {
      const tableWidth = document?.querySelector<HTMLElement>(`div[data-testid="${props.dataTestId ? props.dataTestId : 'connected-table'}"]`)?.offsetWidth || false;
      if (tableWidth) {
        const panwdsColumns = JSON.parse(localStorage.getItem('panwds-columns') || "{}");
        Object.keys(columnWidths).map((column: string) => {
          // we store proportion
          _.set(panwdsColumns, `${props.dataTestId}.${column}`, columnWidths[column] / tableWidth)
        })
        localStorage.setItem('panwds-columns', JSON.stringify(panwdsColumns));
      }
    }
  });

  const customWidth = (columnName:string, tableWidth: number) => {
    if (props.dataTestId) {
      try{
        const localTableInfo = JSON.parse(localStorage.getItem('panwds-columns') || "");
        const savedWidth = _.get(localTableInfo, `${props.dataTestId}.${columnName}`, false);
        if(savedWidth > 1 ) {
          return false;
        }
        if(savedWidth) {
          return tableWidth * savedWidth;
        }
        return false;
      } catch (error) {
        return false;
      }
    }
    return false;
  }

  const AddWidthToColumns = memoize(() => {
    const tableWidth = document?.querySelector<HTMLElement>(`div[data-testid="${props.dataTestId ? props.dataTestId : 'connected-table'}"]`)?.offsetWidth || false;
    if (tableWidth) {
      props.columns.map((col: any) => {
        const localCustomWidth = customWidth(col.accessor, tableWidth);
        if (localCustomWidth) {
          col.width = localCustomWidth;
        } else {
          col.width = col.columnSize ? ((tableWidth * col.columnSize) / 12) : (tableWidth / 12);
        }
        return col;
      })
    }
    return props.columns;
  })

  let tableColumns = AddWidthToColumns();

  const getTableData = () => {
    if(filterBar?.filterBarRequired && filterBar?.filterConfig) {
        if(filterBar?.onlySearchFilterBarRequired && filters?.searchBar !== '') {
          return props.gridData.filter((row:any) => searchGridDataFilter({columns: props.columns, value: filters?.searchBar, row}));
        } else {
          return props.gridData.filter(applyFilters);
        }
    }
    return props.gridData;
  };

  const tableData = getTableData();

  useEffect(() => {
    if(!props.infiniteScroll && props.isBackgroundFetching && props.gridData.length > 0) {
      props.loadGridData();
    }
  }, [props.isBackgroundFetching]);

  let tableDataRowStyle = filterBar?.filterBarRequired ? tableData : props.gridData;

  let classNameContainer = props.infiniteScroll || props.overflowTable ? 'overflow-table-container tw-flex tw-flex-col' : '';
  classNameContainer += isMultiselect || props.singleSelect ? ' row-selection' : ' no-row-selection';
  classNameContainer = props.singleSelect ? classNameContainer + ' single-selection' : classNameContainer;
  classNameContainer = props.tableInnerContainerClassName ? classNameContainer + ' ' + props.tableInnerContainerClassName : classNameContainer;
  classNameContainer += tableDataRowStyle?.length === 0 || classNameForEmpty ? ' noDataStyles' : '';

  return (
    <div className={props.tableContainerStyle ? classes.customTableBox : classes.infiniteScrollTableBox}>
      <div className={classNameContainer}>
        <TableProvider
          columns={tableColumns}
          data={tableData}
          enablePagination={props?.enablePagination}
          isLoading={loading && tableData.length === 0}
          key={tableKey}
          enableRowSelect={isMultiselect || props.singleSelect}
          enableResizeColumns={isResizable}
          enableColumnSort={isSortable}
          rowSelectMode={props.singleSelect ? "single" : "multiple"}
          tableInstanceRef={tableInstanceRef}
          initialState={{
            groupBy: props.initialGroupBy || [],
          }}
          onTableFilterChange={() => {
            if(tableInstanceRef?.current) {
              if (classNameForEmpty !== (tableInstanceRef?.current?.flatRows?.length === 0)){
                setClassNameForEmpty(tableInstanceRef?.current?.flatRows?.length === 0);
              }
            }
          }}
          onColumnWidthChange={onColumnWidthChange}
        >
          <ConnectedTableLayout
            dataMetrics={props.dataMetrics}
            enableHeaderTitle={true}
            dataTestId={props.dataTestId ? props.dataTestId : ''}
            overflow={props.overflowTable || props.infiniteScroll}
            controlLeft={
              <>
                {
                  props.showTileTitle ?
                  <div className={classes.iconedParagraph}>
                    {props.title ?? ''}
                    {props.subtitle ? <Tooltip label={props.subtitle} defaultPlacement="right">
                      <InfoIcon size="xs" />
                    </Tooltip> : ''}
                  </div>
                  : null
                }
                {
                  props.showSelectGroupBy ?
                  <ConnectedGroupBySelect /> : null
                }
              </>
            }
            controlRight={showToolbar && <TableToolBar
              searchFilterRequired={searchFilterRequired}
              filterBar={props.filterBar}
              dataTestId={props.dataTestId}
              dropDownActionsArray={props.dropDownActionsArray || []}
              disableActionsBtn={props.disableActionsBtn} 
            />}
          >
          {filterBar?.filterBarRequired && !filterBar.onlySearchFilterBarRequired &&
              <div className={classes.styleFiletrsCointainer} key={props?.dataTestId}>
                <FilterBar
                  key={props?.dataTestId}
                  name={'FilterAndTable'}
                  filters={filterBar?.filterConfig ? filterBar?.filterConfig : []}
                  saveOnUnmount={false}
                />
              </div>
            }
            <ConnectedTable
              shouldDisableRow={(row: { row: any }) => {
                if(props.lockedRows) {
                  let isRowDisabled = false;
                  props.lockedRows.forEach( (locked, index) => {
                    if(index === row?.row?.index) {
                      isRowDisabled = true;
                    }
                  })
                  return isRowDisabled;
                }
                return (props.disableRow && props.disableRow(row)) || false;
              }}
              dataTestId={props.dataTestId}
              rowSize={props.rowSize ? props.rowSize : 'standard'}
              emptyStateProps={{
                description: props.emptySubtitle ? props.emptySubtitle : undefined,
                heading: props.emptyTitle ? props.emptyTitle : undefined,
                graphic:  (
                  <img width='50px' height='50px' src={EmptyListImage} />
                ),
              }}
            />
          </ConnectedTableLayout>
        </TableProvider>
      </div>
    </div>
  );
}