import {Row} from "../../../../components/FormElements";
import {FormControl, Grid} from "@material-ui/core";
import {Field, useFormState} from "react-final-form";
import {PANWDSCheckboxField, PANWDSChipInput} from "../../../../components/PANWDSElements";
import {PANTile, toast} from "../../../../components";
import {usePermissions, useTranslate} from "../../../../customHooks";
import {useEffect, useState} from "react";
import {dataProvider} from "../../../../dataProvider";
import * as DataModels from "../../../../api/FwaasDataModels";
import * as DataTypes from "../../../../api/FwaasDataTypes";
import {AccordionPanel} from "@panwds/react-ui";

const Index = ({record}: { record: any }) => {
    const translate = useTranslate();
    const {values} = useFormState();
    const { permissions } = usePermissions();

    const [availabilityZoneChoices, setAvailabilityZoneChoices] = useState<any[] | undefined>(undefined);
    const [subnetChoices, setSubnetChoices] = useState<any[] | undefined>(undefined);
    const [vpcSubnetData, setVpcSubnetData] = useState<any | undefined>(undefined);

    useEffect(() => {
        if (record && record.Firewall) {
            if (record?.Firewall && record?.Firewall?.EndpointMode === "ServiceManaged") {
                dataProvider.describe("xaccountroles", '', {
                    AccountId: record?.Firewall.AccountId,
                    DescribeAccount: true,
                    VpcId: record?.Firewall.VpcId
                })
                    .then((response: any) => {
                        if (response.data) {
                            //@ts-ignore
                            if (response.data?.VpcInformations) {
                                //@ts-ignore
                                let vpcData = response.data?.VpcInformations;
                                setVpcSubnetData(({...vpcSubnetData, [vpcData[0].VpcId]: vpcData[0].Subnets}));
                                setSubnetChoices(vpcData[0].SubnetInformations.map((subnet: any) => ({
                                    id: {"SubnetId": subnet['SubnetId']},
                                    name: (subnet['SubnetName']) ? `${subnet['SubnetId']} (${subnet['SubnetName']})` : subnet['SubnetId']
                                })));
                                return;
                            }
                        } else {
                            toast.error(response?.error, {toastId: "xaccountroles-describe"});
                        }
                        setVpcSubnetData({});
                        setSubnetChoices([]);
                    })
                    .catch((e: any) => {
                        toast.error(e?.error, {toastId: "xaccountroles-describe"});
                    });
            }
            if (record?.Firewall && record?.Firewall?.EndpointMode === "CustomerManaged") {
                // if(record?.Firewall?.SubnetMappings){
                //     //@ts-ignore
                //     record.Firewall.SubnetMappings = record.Firewall.SubnetMappings.map((zone: any) => {
                //         return {"AvailabilityZone":(zone?.AvailabilityZoneId) ? `${zone.AvailabilityZone} (${zone.AvailabilityZoneId})` : zone.AvailabilityZone};
                //     });
                // }
                describeAccount({AccountId: record?.Firewall.AccountId})
                    .then((r) => {
                        if (r.data) {
                            //@ts-ignore
                            if (r.data?.AvailabilityZones) {
                                //@ts-ignore
                                let availabilityZoneData = r.data?.AvailabilityZones;
                                let availabilityZoneIds = r.data?.AvailabilityZoneIds;
                                setAvailabilityZoneChoices(availabilityZoneData.map((zone: any, index: number) => ({
                                    id: {AvailabilityZone: (availabilityZoneIds) ? `${zone} (${availabilityZoneIds[index]})` : zone},
                                    name: (availabilityZoneIds) ? `${zone} (${availabilityZoneIds[index]})` : zone
                                })));
                            }
                            return;
                        } else {
                            toast.error(r?.error, {toastId: "account-describe"});
                        }
                        setAvailabilityZoneChoices([]);

                    })
                    .catch((e: any) => {
                        toast.error(e?.error, {toastId: "account-describe"});
                    });
            }
        }
    }, [record]);

    const describeAccount = async (request: DataModels.DescribeLinkAccountRequest): Promise<DataTypes.IFwaasApiResponse> => {
        return dataProvider.describe("xaccountroles", '', {AccountId: request.AccountId, DescribeAccount: true})
            .then((response: any) => {
                let ret = {};
                if (response?.data) {
                    //ret.data = new DataTypes.Account().fromJSON(responseObj.Response);
                    ret = response;
                } else {
                    toast.error(response?.error, {toastId: "xaccountroles-describe"});
                }
                return ret;
            })
            .catch((e: any) => {
                toast.error(e?.error, {toastId: "xaccountroles-describe"});
            });
    }

    return (
        <AccordionPanel title={translate(`resources.firewalls.fields.EndpointManagement`)}
                        caption={translate(`resources.firewalls.fields.EndpointManagementSubtitle`)}>
            {values?.Firewall?.EndpointMode === "CustomerManaged" &&
                <Row>
                    <Grid item xs={6} className="noLeftPadding">
                        <FormControl fullWidth>
                            <Field
                                name="Firewall.MultiVpcEnable"
                                // @ts-ignore
                                component={PANWDSCheckboxField}
                                defaultValue={false}
                                label={translate(`resources.firewalls.fields.Shared`)}
                                type="checkbox"
                                disabled={!permissions?.AssociateSubnetMappings}
                                dataMetrics={"cloudngfw-firewall-edit-multivpcenabled"}
                            />
                        </FormControl>
                    </Grid>
                </Row>
            }
            {values?.Firewall?.EndpointMode === "ServiceManaged" &&
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <Field
                            name="Firewall.SubnetMappings"
                            format={(value: any) => value.map((v: any) => v['SubnetId'])}
                            row
                            // @ts-ignore
                            component={PANWDSChipInput}
                            title={translate(`resources.firewalls.fields.Subnet`)}
                            items={(subnetChoices || []).map(sc => (sc.id['SubnetId']))}
                            loading={subnetChoices === undefined}
                            dataMetrics={"cloudngfw-firewall-edit-subnet"}
                            disabled={!permissions?.AssociateSubnetMappings}
                            disabledTags={!permissions?.DisassociateSubnetMappings}
                            enableArrayInput
                            formatter={(value: any) => ({SubnetId: value})}
                            menuStyle={{width: '12rem'}}
                        />
                    </FormControl>
                </Grid>
            }
            {values?.Firewall?.EndpointMode === "CustomerManaged" &&
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <Field
                            name="Firewall.SubnetMappings"
                            format={(value: any) => value?.map((v: any) => v['AvailabilityZone'])}
                            row
                            // @ts-ignore
                            component={PANWDSChipInput}
                            title={translate(`resources.firewalls.fields.AvailabilityZone`)}
                            items={(availabilityZoneChoices || []).map(sc => (sc.id['AvailabilityZone']))}
                            loading={availabilityZoneChoices === undefined}
                            disabled={!permissions?.AssociateSubnetMappings}
                            disabledTags={!permissions?.DisassociateSubnetMappings}
                            dataMetrics={"cloudngfw-firewall-edit-availability-zone"}
                            enableArrayInput
                            formatter={(value: any) => ({AvailabilityZone: value})}
                            menuStyle={{width: '12rem'}}
                        />
                    </FormControl>
                </Grid>
            }
        </AccordionPanel>
    );
};
export default Index;
