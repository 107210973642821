import { Box, Grid } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { find, isEmpty } from 'lodash';
import {  useEffect, useState } from 'react';
import { Field, withTypes } from 'react-final-form';
import { useHistory, withRouter, useLocation } from 'react-router-dom';
import { Button as PANWDSButton, LoadingButton }  from "@panwds/react-ui";
import { PANTile, PANTitle, toast } from '../../components';
import { Row } from '../../components/FormElements';
import { dataProvider } from '../../dataProvider';
import { validatePasswordRegex } from '../../utils/validate';
import {PANWDSInput, PANWDSChipInput, PANWDSModal, PANWDSBreadcrumbs} from '../../components/PANWDSElements';
import { authProvider } from '../../authProvider';
import { ChangePasswordForm } from './components/ChangePasswordForm';
import { useTranslate } from '../../customHooks';
import {ReduxActions, ReduxResources} from "../../redux";
import {useAppDispatch} from "../../app/hooks";
import {ApplicationConfigManager} from "../../types";
import CircularLoader from '../../components/CircularLoader/CircularLoader';

export const PANMainContainer = withStyles(() => ({
  root: {
    background: '#F4F5F5',
    padding: '16px 16px 80px 16px',
    position: 'relative',
    height: '100%',
  },
}))(Box);


const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '1em',
    border: '1px solid #DADBDB',
    width: '100%',
  },
  media: {
    height: 140,
  },
  header: {
    border: '1px solid #DADBDB',
  },
  title: {
    paddingBottom: '0.5em',
  },
  actionSpacer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  toolbar: {
    display: 'flex',
    gap: theme.spacing(1),
    justifyContent: 'end',
    '-webkit-justify-content': 'flex-end',
    alignItems: 'start',
    boxSizing: 'border-box',
    padding: '10px 0',
    minHeight: 'initial',
  },
}));

interface ChangePasswordFormValues {
  oldPassword?: string,
  password?: string,
  reEnterPassword?: string;
}

const UserEditToolbar = (toolbarProps: any) => {
  const translate = useTranslate();
  const history = useHistory();
  const { pristine, submitting, valid, classes, handleSubmit, invalid, validating } = toolbarProps;
  return (
    <div className={classes.toolbar}>
      <PANWDSButton
        size="md"
        appearance="secondary"
        disabled={submitting}
        onClick={() => {
          history.goBack();
        }}
        dataMetrics="cloudngfw-users-edit-cancel"
      >
        {translate(`resources.users.fields.Cancel`)}
      </PANWDSButton>
      <LoadingButton
        dataResult={{
          loading: !!submitting,
          success: true
        }}
        size="md"
        appearance="primary"
        type="submit"
        disabled={submitting || pristine || !valid}
        dataMetrics="cloudngfw-users-edit-save"
      >
        {translate(`resources.users.fields.Save`)}
      </LoadingButton>
    </div>
  );
};

const UsersEdit = (props: any) => {
  const [record, setRecord] = useState<any>({});
  const [roleChoice, setRoleChoice] = useState<any>([]);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const search = useLocation().search;
  const userName = decodeURIComponent(new URLSearchParams(search).get('username') || '');

  const classes = useStyles();
  const translate = useTranslate();
  const history = useHistory();
  const [open, setOpen] = useState(false)

  useEffect(() => {
    loadRoleData();
    dataProvider.describe('users', '', { UserName: userName }).then(async (res: any) => {
      if (res.data) {
        //@ts-ignore
        setRecord({ ...res.data });
      }
      else {
        toast.error(res?.error, { toastId: "users-describe" });
      }
    })
      .catch((e: any) => {
        toast.error(e?.error, { toastId: "users-describe" });
      });
  }, []);

  const loadRoleData = () => {
    setLoading(true);
    Promise.all([
      dataProvider.get('permissionpolicies'),
      dataProvider.get('xaccountroles')])
      .then((values: any) => {
        const allowedRoles = values[0]?.data?.Permissions || [];
        const accountIds = values[1]?.data?.AccountIds as string[] || [];
        const allowedRolesChoice: object[] = [];
        const gra = (allowedRoles && find(allowedRoles, { 'Policy': 'GlobalRuleStackAdmin' })) ? true : false;

        allowedRoles.forEach((item: any) => {
          /* if (item?.AccountIdParam === 'Mandatory' && gra && item?.Policy === 'LocalRuleStackAdmin') {
          } else if (item?.AccountIdParam === 'Mandatory') { */
          if (item?.AccountIdParam === 'Mandatory') {
            accountIds.forEach((account: string) => {
              allowedRolesChoice.push({
                id: {
                  AccountId: account,
                  Policy: item?.Policy,
                },
                name: `${account}/${item?.Policy}`,
              });
            })
          }
          else {
            allowedRolesChoice.push(
              {
                id: {
                  Policy: item?.Policy,
                },
                name: item?.Policy,
              }
            );
          }
        });
        // if (gra){
        //   accountIds.forEach((account: string) => {
        //     allowedRolesChoice.push({
        //       id: {
        //         AccountId: account,
        //         Policy: 'LocalRuleStackAdmin',
        //       },
        //       name: `${account}/LocalRuleStackAdmin`,
        //     });
        //   })
        // }
        let choiceOfRoles:any = [];
        allowedRolesChoice.map((data: any) => choiceOfRoles.push({value: data?.name}));
        setRoleChoice(allowedRolesChoice);
        setLoading(false);
      }).catch((error: any) => {
        toast.error(error?.error, { toastId: "permissions-roles-get" })
      })
  };

  const onSubmit = async (values: any, form: any) => {
      const userEmail = ApplicationConfigManager.getInstance().getUserEmail();
    setSubmitting(true);
    dataProvider
      .update('users', values)
      .then(async (response: any) => {
        await dispatch(ReduxActions.updateResource({resource: ReduxResources.USER})({UserName: userName}));
        if (response?.data) {
          ApplicationConfigManager.getInstance().ensureUserDisplayName()
          history.goBack();
        }
        else {
          toast.error(response?.error, { toastId: "users-update" });
        }
      })
      .catch((e: any) => {
        toast.error(e?.error, { toastId: "users-update" });
      }).finally(() => {
        setSubmitting(false);
      });
  };

  const validate = (values: ChangePasswordFormValues) => {
    const errors: ChangePasswordFormValues = {};

    if (!values.oldPassword) {
      errors.oldPassword = translate('validation.required');
    }
    if (!values.password) {
      errors.password = translate('validation.required');
    }
    //@ts-ignore
    if (!validatePasswordRegex(values.password)) {
      errors.password = translate('validation.invalidPassword');
    }
    if (!values.reEnterPassword) {
      errors.reEnterPassword = translate('validation.required');
    }
    if (values.reEnterPassword !== values.password) {
      errors.reEnterPassword = translate('validation.missMatch');
    }
    return errors;
  };

  const { Form } = withTypes<ChangePasswordFormValues>();

  const handleChipInputFormatter = (value: any) => {
    const values = value.split('/');
    let o: any = {};
    if (values.length > 1) {
      o.AccountId = values[0];
      o.Policy = values[1];
    } else {
      o.Policy = values[0];
    }
    return o;
  }

  const handleChipValueFormat = (v: any) => (v?.AccountId ? `${v?.AccountId}/${v?.Policy}` : `${v?.Policy}`);

  return (
    <>
      <PANTitle divider={false} />
      <PANWDSBreadcrumbs
        mapping={{
          users : translate(`resources.users.name`),
          [`edit`]: userName
        }}
      />
      <PANTitle title={record ? record.FirstName + ' ' + record.LastName : userName} divider />
      <Box style={{ position: 'relative' }}>
        <PANMainContainer style={{ padding: 0, paddingBottom: 80 }}>
          <CircularLoader loading={isEmpty(record) || submitting} />
          <Form
            onSubmit={onSubmit}
            initialValues={record}
            render={(formProps) => {
              const { handleSubmit, values } = formProps;
              return (
                <form onSubmit={handleSubmit} style={{ margin: 16 }}>
                  <Grid container style={{ width: 'auto' }}>
                    <PANTile
                      size={12}
                    >
                      <Row>
                        <Grid item xs={12} sm={6}>
                          <Field
                            name="FirstName"
                            // @ts-ignore
                            component={PANWDSInput}
                            title={translate(`resources.users.fields.FirstName`)}
                            dataMetrics="cloudngfw-users-edit-firstname"
                          />
                        </Grid>
                      </Row>
                      <Row>
                        <Grid item xs={12} sm={6}>
                          <Field
                            name="LastName"
                            // @ts-ignore
                            component={PANWDSInput}
                            title={translate(`resources.users.fields.LastName`)}
                            dataMetrics="cloudngfw-users-edit-lastname"
                          />
                        </Grid>
                      </Row>
                      {values && values?.Identity !== "Idp_O" && <Row>
                        <Grid item xs={12} sm={6}>
                          <PANWDSButton
                            appearance='secondary'
                            size="lg"
                            onClick={() => setOpen(true)}
                          >
                            {translate(`resources.users.fields.ChangePassword`)}
                          </PANWDSButton>
                        </Grid>
                      </Row>
                      }
                      <Row>
                        <Grid item xs={12} sm={6}>
                        <Field
                          name="Permissions"
                          format={(value) => value?.map(handleChipValueFormat)}
                          row
                          // @ts-ignore
                          component={PANWDSChipInput}
                          title={translate(`resources.users.fields.Roles`)}
                          items={roleChoice?.map((rc: any) => handleChipValueFormat(rc?.id))}
                          selectedItemsFormat={'Policy'}
                          loading={loading}
                          enableArrayInput
                          formatter={handleChipInputFormatter}
                          dataMetrics={"cloudngfw-users-edit-permissions"}
                        />
                        </Grid>
                      </Row>
                    </PANTile>
                  </Grid>
                  <UserEditToolbar {...formProps} submitting={submitting} classes={classes} />
                </form>
              );
            }}
          />
          <PANWDSModal
            title={translate('changePassword.title')}
            onClose={() => setOpen(false)}
            isOpen={open}
            size="md"
            dataTestId="cloudngfw-users-edit-modal"
            dataMetrics="cloudngfw-users-edit-modal"
            modalBody={<ChangePasswordForm validate={validate} Form={Form} onOpen={setOpen} />}
          />

        </PANMainContainer>
      </Box>
    </>
  );
};

export default withRouter(UsersEdit);
