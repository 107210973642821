import { makeStyles } from "@material-ui/core";
import { Textarea } from "@panwds/react-ui";
import { PANFieldContainer, PANHelpText, PANMutedText } from "../FormElements/styles";
import {PANWDSLabel} from "./PANWDSLabel";

const useStyles = makeStyles((theme) => ({
    error: {
        border: '1px solid #D13C3C',
        borderRadius: 4,
    },
    errorText: {
        color: "#D13C3C",
    },
    disabledInput: {
        '& input': {
            '--tw-text-opacity': 1,
            color: "rgb(184 184 184 / var(--tw-text-opacity))",
        }
    },
    readOnly: {
        border: '1px solid #DADBDB'
    }
}));

export const PANWDSTextarea = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
}) => {
    const classes = useStyles();
    const existerror = !!(touched && error);
    const { title, required, note, placeholder, enableArrayValue, rowsMin = 3, rowsMax = 4, dataMetrics = undefined, disabled, readOnly } = props;
    let value = inputProps.value;
    if (enableArrayValue){
        value = value ? value.join('\n') : "";
    }

    return <PANFieldContainer>
        {title && <PANWDSLabel disabled={disabled} required={required} title={title}/>}
        <Textarea
            addClassName={disabled && classes.disabledInput || readOnly && classes.readOnly}
            placeholder={placeholder}
            value={value}
            onChange={(e) => {
                if (enableArrayValue) {
                    inputProps.onChange(e.currentTarget.value.split('\n'));
                }else {
                    inputProps.onChange(e.currentTarget.value);
                }
            }}
            onBlur={inputProps.onBlur}
            onFocus={inputProps.onFocus}
            dataMetrics={dataMetrics}
            rowsMax={rowsMax}
            rowsMin={rowsMin}
            appearance={existerror ? "error" : "default"}
            readOnly={readOnly}
        />
        {note && (<PANMutedText disabled={disabled}>{note}</PANMutedText>)}
        {existerror && (<PANHelpText>{error}</PANHelpText>)}
    </PANFieldContainer>;
};
