import jwt_decode from "jwt-decode";
import { MouseEvent, ReactNode, RefObject } from 'react';
import * as DataTypes from "./api/FwaasDataTypes";
import { authProvider } from './authProvider';
import appConfig from "./config.json";
import { dataProvider } from "./dataProvider";
import { PERMISSIONS } from "./config/permissions";
import isEmpty from "lodash/isEmpty";
import EventEmitter from "./eventEmitter";
import { BaseCSSProperties } from '@material-ui/styles';
import { toast } from 'react-toastify';
import {store} from "./app/store";
import {ReduxActions, ReduxResources} from "./redux";
import {useOktaAuthClient} from "./customHooks";
import CognitoManager from "./config/cognitoIdentity";
import OktaManager from "./config/oktaIdentity";

export interface Record {
    id: Identifier;
    [key: string]: any;
}

export type ThemeName = 'light' | 'dark';

export declare type Identifier = string | number;

export interface UserIdentity {
    id: Identifier;
    fullName?: string;
    avatar?: string;
    [key: string]: any;
}

export declare type AuthProvider = {
    checkUser: (params: any) => Promise<any>;
    logout: (params: any) => Promise<void | false | string>;
    checkAuth: (params: any) => Promise<void>;
    checkError: (error: any) => Promise<void>;
    getPermissions: (params: any) => Promise<any>;
    getIdentity?: () => Promise<UserIdentity>;
    [key: string]: any;
};

export declare type OktaProvider = {
    [key: string]: any;
};

export declare type CognitoProvider = {
    login: (params: any) => Promise<any>;
    [key: string]: any;
};

export type SupportedRegion = {
    ApiUrl: string,
    RegionCode: string,
    RegionDisplayName: string,
    UserPoolId: string,
    UserPoolAppClientId: string
};

export const SupportedRegionEmpty = {
    ApiUrl: "",
    RegionCode: "",
    RegionDisplayName: "",
    UserPoolId: "",
    UserPoolAppClientId: "",
};

export type ApplicationConfig = {
    awsAccountStatus: string;
    setCspWorkFlow: boolean,
    currentRegion: SupportedRegion;
    supportedRegions: SupportedRegion[];
    userDisplayName: any;
    xApiKey: string;
    permissions: { [key: string]: string | boolean },
    rawPermissions: any,
    externalID: string,
    existsInOkta: boolean,
    existsInCognito: boolean,
    // TODO: save check user api response for later user
};

export class ApplicationConfigManager {
    private static instance: ApplicationConfigManager = new ApplicationConfigManager();
    static cognitoInstance: CognitoManager = new CognitoManager();
    static oktaInstance: OktaManager = new OktaManager();

    private currentConfig: ApplicationConfig = {
        currentRegion: SupportedRegionEmpty,
        supportedRegions: Object.assign(Object.values(appConfig).filter((obj) => obj.hasOwnProperty("ApiUrl"))),
        userDisplayName: "",
        xApiKey: "",
        permissions: {},
        awsAccountStatus: "none",
        setCspWorkFlow: false,
        rawPermissions: [],
        externalID: "",
        existsInCognito: false,
        existsInOkta: false,
    };

    ApplicationConfigManager() {
        this.loadConfig();
    }

    static getInstance() {
        return ApplicationConfigManager.instance;
    }

    async clearSession() {
        if (localStorage.getItem('idToken'))
            localStorage.removeItem('idToken');

        const cognitoInstance = ApplicationConfigManager.cognitoInstance;
        cognitoInstance.clearSession();

        const oktaInstance = ApplicationConfigManager.oktaInstance;
        await oktaInstance.clearSession();


        //console.log('currentRegion removed' + localStorage.getItem('currentRegion'));
        if (localStorage.getItem('currentRegion'))
            localStorage.removeItem('currentRegion');

        if (localStorage.getItem('awsAccountStatus'))
            localStorage.removeItem('awsAccountStatus');

        if (localStorage.getItem('oktaRegistrationModal'))
            localStorage.removeItem('oktaRegistrationModal');

        if (localStorage.getItem('okta-cache-storage'))
            localStorage.removeItem('okta-cache-storage');


        // this.currentConfig.userDisplayName = "";
        // this.currentConfig.xApiKey = "";

        this.currentConfig = {
            ...this.currentConfig,
            userDisplayName: "",
            xApiKey: "",
            permissions: {},
            awsAccountStatus: "none",
            setCspWorkFlow: false,
            rawPermissions: [],
            externalID: "",
            existsInCognito: false,
            existsInOkta: false,
        };

        sessionStorage.clear();
        EventEmitter.emit("setCspWorkFlow", false);
    }

    handleCSPWorkFlow = async () => {
        let newAppConfig = ApplicationConfigManager.getInstance().getConfig();
        newAppConfig.setCspWorkFlow = true;
        await ApplicationConfigManager.getInstance().setConfig(newAppConfig);
        EventEmitter.emit("setCspWorkFlow", true);
    }

    getCspWorkFlow(): Promise<any> {
        return new Promise((resolve, reject) => {
            store.dispatch(ReduxActions.describe({resource: ReduxResources.SUPPORT})({})).unwrap()
                .then(async (response: DataTypes.IFwaasApiResponse) => {
                    if (response.data) {
                        this.currentConfig.externalID = response.data.ExternalId;
                        if (response.data.SupportInformations && isEmpty(response.data.SupportInformations[0])) {
                            await this.handleCSPWorkFlow();
                            resolve(true);
                        } else if (response.data.SupportInformations && process.env.REACT_APP_SHOW_CSP === "true") {
                            const supportInfo = response.data.SupportInformations[0];
                            if (!supportInfo.SupportAccountId) {
                                await this.handleCSPWorkFlow();
                                resolve(true);
                            } else {
                                resolve(false);
                            }
                        } else {
                            resolve(false);
                        }
                    } else {
                        reject(response?.error?.error);
                    }
                })
                .catch((e: any) => {
                    reject(e?.error);
                });
        });
    }

    refreshAPIToken(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (isEmpty(sessionStorage.getItem("tenant"))) {
                reject(null);
            } else {
                const newAppConfig = ApplicationConfigManager.getInstance().getConfig();
                newAppConfig.xApiKey = sessionStorage.getItem("tenant");
                resolve(newAppConfig.xApiKey);
            }
        });

    }

    async ensureSession() {
        try {
            const cognitoInstance = ApplicationConfigManager.cognitoInstance;
            const oktaInstance = ApplicationConfigManager.oktaInstance;
            await cognitoInstance.loadConfig();
            await oktaInstance.loadConfig();
        } catch (e) {
            console.log({ e });
            this.clearSession();
        }
    }

    async isSessionAvailable() {
        try {
            const cognitoInstance = ApplicationConfigManager.cognitoInstance;
            const oktaInstance = ApplicationConfigManager.oktaInstance;
            if (await cognitoInstance.isSessionAvailable()) {
                await this.loadConfig();
                return;
            } else if (await oktaInstance.isSessionAvailable()) {
                await this.loadConfig();
                return;
            } else {
                this.clearSession();
            }
        } catch (e) {
            console.log({ e })
            this.clearSession();
        }
    }

    async loadConfig() {
        if (!this.currentConfig.supportedRegions) {
            //console.log("loading config regions ...");
            this.currentConfig.supportedRegions = Object.assign(Object.values(appConfig).filter((obj) => obj.hasOwnProperty("ApiUrl")));
        }

        const regionFromStorage = localStorage.getItem('currentRegion');
        if (regionFromStorage && regionFromStorage !== "") {
            //console.log("loading current region from storage ..." + regionFromStorage);
            this.currentConfig.currentRegion = (this.currentConfig.supportedRegions.filter(curRegion => curRegion.RegionCode === regionFromStorage))[0];
            //console.log('setting currentRegion : ' + this.currentConfig.currentRegion.RegionCode);
            localStorage.setItem('currentRegion', this.currentConfig.currentRegion.RegionCode);
        } else {
            //console.log("loading 1st region in config ...");
            const selectedRegion = Object.assign((Object.values(appConfig))[0]);
            this.currentConfig.currentRegion = selectedRegion;
            //console.log('setting currentRegion : ' + selectedRegion.RegionCode);
            localStorage.setItem('currentRegion', selectedRegion.RegionCode);
        }

        const idToken = localStorage.getItem('idToken');
        if (isEmpty(idToken)) {
            await this.isSessionAvailable();
        } else {
            try {
                this.currentConfig.xApiKey = await this.refreshAPIToken();

                this.currentConfig.setCspWorkFlow = await this.getCspWorkFlow();

                this.pendoInitialize(this.currentConfig.xApiKey);

                if (!isEmpty(localStorage.getItem('awsAccountStatus'))) {
                    this.currentConfig.awsAccountStatus = localStorage.getItem('awsAccountStatus') || "none";
                    this.pendoUpdate(this.currentConfig.awsAccountStatus);
                }
                await this.ensureUserDisplayName();
                await this.ensureSession();
            } catch (e) {
                throw e;
            }
        }
    }

    getConfig(): ApplicationConfig {
        return this.currentConfig;
    }

    async setConfig(config: any, signup: boolean = false, noCheck: boolean = false) {
        try {
            if (!noCheck) {
                let xApiKey = config.xApiKey;
                if (isEmpty(config.xApiKey)) {
                    xApiKey = await this.refreshAPIToken();
                }
                //console.log("Ensure: xAPIKey refreshed", xApiKey);
                config.xApiKey =  xApiKey;
                if (isEmpty(config.permissions) && !signup) {
                    await this.ensureUserDisplayName();
                }
            }
            this.currentConfig = config;
            // console.log('setting currentRegion from switchRegion' + config.RegionCode);
            localStorage.setItem('currentRegion', this.currentConfig.currentRegion.RegionCode);
            localStorage.setItem('awsAccountStatus', this.currentConfig.awsAccountStatus);
        } catch (e: any) {
            console.log(e?.error);
        }
    }

    generateUserDisplayName(res: any) {
        if (isEmpty(res?.data?.FirstName)) {
            return '';
        } else {
            return `${res?.data?.FirstName} ${res?.data?.LastName}`
        }
    }

    async ensureUserDisplayName(callback?: (s: string) => void) {
        const applicationConfigManager = ApplicationConfigManager.getInstance();
        if (localStorage.getItem('idToken')) {
            var token: any = localStorage.getItem('idToken');
            var decodedToken: any = jwt_decode(token);
            if (decodedToken && decodedToken['email']) {
                await dataProvider.describe("users", '', { UserName: decodedToken['email'] })
                    .then(async (res: any) => {
                        if (res.data) {
                            //@ts-ignore
                            let userDisplayName = this.generateUserDisplayName(res);
                            this.currentConfig.userDisplayName = isEmpty(userDisplayName) ? decodedToken['email'] : userDisplayName;
                            const permissions = res?.data?.Permissions.map((permission: any) => PERMISSIONS[permission.Policy]).flat();
                            const uniquePermissions = [...Array.from(new Set<string>(permissions))];
                            this.currentConfig.rawPermissions = res?.data?.Permissions;
                            this.currentConfig.permissions = Object.fromEntries(uniquePermissions.map((permission => [permission, true])));
                            callback && callback(userDisplayName);
                        } else {
                            applicationConfigManager.clearSession();
                            await authProvider.logout(null);
                        }
                    })
                    .catch((e: any) => {
                        console.log(e?.error);
                        toast.error(e?.error, { toastId: `display name error` });
                        applicationConfigManager.clearSession();
                        authProvider.logout(null);
                    });
            }
        }
    }

    getUserDisplayName(callback?: (s: string) => void): string {
        if (!this.currentConfig.userDisplayName)
            if (callback) {
                this.ensureUserDisplayName().then((name: any) => callback(name));
            }
        return this.currentConfig.userDisplayName;
    }

    getUserEmail(): string {
        const token: any = localStorage.getItem('idToken');
        const decodedToken: any = jwt_decode(token);
        let userEmail = '';
        if (decodedToken && decodedToken['email']) {
            userEmail = decodedToken['email'];
        }
        return userEmail;
    }

    async getPermissions(callback?:(s: string)=>void): Promise<any> {
      if (!this.currentConfig.permissions || Object.keys(this.currentConfig.permissions).length === 0) {
        await new Promise<void>((resolve) => {
          this.ensureUserDisplayName().then(resolve);
        });
      }
      return {rawPermissions: this.currentConfig.rawPermissions, permissions: this.currentConfig.permissions};
    }

    getSupportedRegions(): SupportedRegion[] {
        return this.currentConfig.supportedRegions;
    }

    getAPIBaseUri(): string {
        return `${this.currentConfig.currentRegion.ApiUrl}/v1`;
    }

    getConfigAPIBaseUri(): string {
        return `${this.currentConfig.currentRegion.ApiUrl}/v1`;
    }

    pendoInitialize(token: string) {
        let pendo = window.pendo;
        let idToken: any = localStorage.getItem('idToken');
        if (idToken) {
            var decodedToken: any = jwt_decode(idToken);
            if (decodedToken && decodedToken['email']) {
                var username: string = decodedToken["email"];
                var domain: string = token;
                if (username) {
                    domain = username.substring(username.lastIndexOf('@') + 1);
                    //console.log(domain + " " + username);

                    pendo.initialize({
                        visitor: {
                            id: username,   // Required if user is logged in
                            email: username,       // Recommended if using Pendo Feedback, or NPS Email
                            // full_name:    // Recommended if using Pendo Feedback
                            // role:         // Optional

                            // You can add any additional visitor level key-values here,
                            // as long as it's not one of the above reserved names.
                            apikey: token,
                            lastlogintime: (new Date()).toISOString(),
                            domain: ApplicationConfigManager.getInstance().getAPIBaseUri(),
                            region: ApplicationConfigManager.getInstance().getConfig().currentRegion.RegionCode
                        },

                        account: {
                            id: domain, // Required if using Pendo Feedback
                            // name:          // Optional
                            // is_paying:    // Recommended if using Pendo Feedback
                            // monthly_value:// Recommended if using Pendo Feedback
                            // planLevel:    // Optional
                            // planPrice:    // Optional
                            // creationDate: // Optional

                            // You can add any additional account level key-values here,
                            // as long as it's not one of the above reserved names.
                        }
                    });
                }
            }
        }
    }

    pendoUpdate(type: string) {
        const pendo = window.pendo;
        const idToken: any = localStorage.getItem('idToken');
        if (idToken) {
            const decodedToken: any = jwt_decode(idToken);
            if (decodedToken && decodedToken['email']) {
                const username: string = decodedToken["email"];
                if (username) {
                    pendo.updateOptions({
                        visitor: {
                            id: username,
                            awsaccountstatus: type
                        },
                    });
                }
            }
        }
    }
}

/**
 * Redux state type
 */
export interface ReduxState {
    // leave space for custom reducers
    [key: string]: any;
}

export interface AppState extends ReduxState {
    theme: ThemeName;
    config: ApplicationConfig;
}

export interface Firewall extends Record { }

export interface Rules extends Record { }

export interface RuleStack extends Record { }

export interface Users extends Record { }

export interface Accounts extends Record { }

export type ReviewStatus = 'accepted' | 'pending' | 'rejected';

export type ButtonAppearances = "primary" | "primary-destructive" | "secondary" | "secondary-destructive" | "tertiary" | "tertiary-clear" | "clear";

export interface Review extends Record {
    date: Date;
    status: ReviewStatus;
    customer_id: Identifier;
    product_id: Identifier;
}

export interface IUsersActionButton {
    basePath?: string;
    handleToggle: () => void;
    open: boolean;
    ref: RefObject<HTMLDivElement>;
    options: IOptions[];
    handleClose: (event: MouseEvent<Document, MouseEvent>) => void;
    refresh: (hard?: boolean | undefined) => void
}

export interface IOptions {
    id: number | string;
    label: string;
    dataTestId?: string | undefined;
    action: (
        event: MouseEvent<HTMLLIElement, MouseEvent>,
        index: number,
        cb?: (arg?: any) => void,
        selected?: any
    ) => void;
}

export interface IPANTitle {
    title?: string;
    subtitle?: string;
    tag?: string;
    children?: ReactNode[];
    region?: boolean;
    divider?: boolean;
    overviewPanel?: IPanelOptions[];
    paddingContainer?: string;
}

export interface IPanelOptions {
    key: string;
    values: IValueOptions[];
    isLoading?: boolean;
    openModal?: any;
}

export interface IValueOptions {
    text: any,
    isLink?: boolean;
    linkOption?: 'modal' | 'internal' | 'external' | 'dropdown';
    linkToOpen?: string;
    isStatus?: boolean;
    isStatusLink?: boolean;
    isDropdownLink?: boolean;
    dropDownActionsArray?: ISplitButtonAction[];
    isNormalText?: boolean;
}
export interface IPANBreadcrumbs {
    path?: string;
    mapping?: any;
    queryParams?: string;
}

export interface IPANModal {
    children?: ReactNode;
    handleClose: () => void;
    open: boolean;
    title: string;
    maxWidth?: false | "md" | "xs" | "sm" | "lg" | "xl" | undefined;
    dismissButton?: ReactNode;
    submitButton?: ReactNode;
    customActions?: ReactNode;
    contentStyle?: BaseCSSProperties
}

export interface IDropDownActions {
    showTooltip?: any;
    title?: string,
    actionsMap: { menuText: string, handleAction: Function, dataTestId?: string, disabled?: boolean, confirmModal?: string, dataMetrics: string }[],
    dataTestId?: string,
    disabled?: boolean,
    type?: string, // primary | secondary | button | button_secondary
    handleButtonAction?: Function, // only for type = button
    disabledAddLogic?: boolean,
    dataMetrics?: string,
    appearance?: ButtonAppearances,
    icon?: any;
    items?: any;
    handleAction?: Function;
    confirmModal?: string;
    getVPCGroupItems?: Function;
}

type ToolbarButton = {
    type: "button" | "button_with_tooltip";
    title: string;
    action: Function;
    disabled?: boolean,
    disableFn?: Function,
    requireSelectedRows?: boolean,
    dataMetrics?: string,
    appearance: ButtonAppearances,
    icon?: any,
    showTooltip?: any
}

type ToolbarDropdown = {
    type: "dropdown";
    title: string,
    actions: {
        title: string,
        action: Function,
        disabled?: boolean,
        confirmModal?: string,
        dataMetrics: string
    }[],
    disabled?: boolean,
    disableFn?: Function,
    requireSelectedRows?: boolean,
    dataMetrics?: string,
    appearance?: ButtonAppearances,
    icon?: any;
}

export type ITableToolbar = ToolbarButton | ToolbarDropdown;

export interface IFiltersConfig {
    Component: any,
    label: string,
    name: string,
    static?: boolean,
    required?: boolean,
    defaultValues?: any,
    columnValue?: any,
    items?: any,
    search?: boolean
}

export interface ITabsList {
    activeTabValue: string,
    tabsList: any[],
    handleTabsChange: Function
}

export interface ISearchBarFilters {
    onlySearchFilterBarRequired?: boolean,
    filterBarRequired?: boolean,
    filterConfig?: any
}

export interface ISplitButtonAction {
    menuText: string,
    progressText: string,
    handleAction: Function,
    enabled?: boolean,
}

export interface IPANSearchbar {
    show?: boolean,
    text?: string,
    onChange?: any,
    disabled?: boolean,
    defaultValue?: string
}

export interface IPANToolbar {
    title?: string;
    description?: string;
    refresh?: (hard?: boolean | undefined) => void;
    dropDownActionsArray?: IDropDownActions[],
    searchBar?: IPANSearchbar,
    nested?: boolean,
    selectedItems: any,
}

export interface IPANNoData {
    title: string;
    message: string;
    toolbar?: React.ReactElement;
    children?: React.ReactNode;
}

export interface IPANTile {
    children: React.ReactNode;
    size: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    titleVariant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    fontSize?: number;
    title?: string;
    subtitle?: string;
    titlePadding?: string;
    padding?: string;
    divider?: boolean;
    titleActions?: React.ReactNode;
}

export interface IChipInitValue {
    Key: string;
    Value: string;
}

export interface IChipOptions extends IChipInitValue {

}

declare global {
    interface Window {
        restServer: any;
    }
}

export type DataProvider = {
    getList: <RecordType extends Record = Record>(
        resource: string,
        params?: GetListParams
    ) => Promise<GetListResult<RecordType>>;

    getOne: <RecordType extends Record = Record>(
        resource: string,
        params: GetOneParams
    ) => Promise<GetOneResult<RecordType>>;

    getMany: <RecordType extends Record = Record>(
        resource: string,
        params: GetManyParams
    ) => Promise<GetManyResult<RecordType>>;

    getManyReference: <RecordType extends Record = Record>(
        resource: string,
        params: GetManyReferenceParams
    ) => Promise<GetManyReferenceResult<RecordType>>;

    update: <RecordType extends Record = Record>(
        resource: string,
        params: UpdateParams
    ) => Promise<UpdateResult<RecordType>>;

    updateMany: (
        resource: string,
        params: UpdateManyParams
    ) => Promise<UpdateManyResult>;

    create: <RecordType extends Record = Record>(
        resource: string,
        params: CreateParams
    ) => Promise<CreateResult<RecordType>>;

    delete: <RecordType extends Record = Record>(
        resource: string,
        params: DeleteParams
    ) => Promise<DeleteResult<RecordType>>;

    deleteMany: (
        resource: string,
        params: DeleteManyParams
    ) => Promise<DeleteManyResult>;

    [key: string]: any;
};

export interface GetListParams {
    pagination?: PaginationPayload;
    sort?: SortPayload;
    filter?: any;
    data?: any
}
export interface GetListResult<RecordType extends Record = Record> {
    data: RecordType[];
    total: number;
    nextToken?: string;
    validUntil?: ValidUntil;
    error?: any;
}

export interface GetOneParams {
    id: Identifier;
}
export interface GetOneResult<RecordType extends Record = Record> {
    data: RecordType;
    validUntil?: ValidUntil;
}

export interface GetManyParams {
    ids: Identifier[];
}
export interface GetManyResult<RecordType extends Record = Record> {
    data: RecordType[];
    validUntil?: ValidUntil;
}

export interface GetManyReferenceParams {
    target: string;
    id: Identifier;
    pagination: PaginationPayload;
    sort: SortPayload;
    filter: any;
}
export interface GetManyReferenceResult<RecordType extends Record = Record> {
    data: RecordType[];
    total: number;
    validUntil?: ValidUntil;
}

export interface UpdateParams<T = any> {
    id: Identifier;
    data: T;
    previousData: Record;
}
export interface UpdateResult<RecordType extends Record = Record> {
    data: RecordType;
    validUntil?: ValidUntil;
    error?: any;
}

export interface UpdateManyParams<T = any> {
    ids: Identifier[];
    data: T;
}
export interface UpdateManyResult {
    data?: Identifier[];
    validUntil?: ValidUntil;
}

export interface CreateParams<T = any> {
    data: T;
}
export interface CreateResult<RecordType extends Record = Record> {
    data: RecordType;
    validUntil?: ValidUntil;
}

export interface DeleteParams {
    id: Identifier;
    previousData?: any;
}
export interface DeleteResult<RecordType extends Record = Record> {
    data: RecordType;
    error?: any;
}

export interface DeleteManyParams {
    ids: Identifier[];
}
export interface DeleteManyResult {
    data?: Identifier[];
}

export interface SortPayload {
    field: string;
    order: string;
}
export interface FilterPayload {
    [k: string]: any;
}
export interface PaginationPayload {
    page: number;
    perPage: number;
}

export type ValidUntil = Date;

export interface SelectItems {
    text: string;
    value: string | number;
}
