import { makeStyles } from "@material-ui/core";
import { SelectWithSearch, Button, Select } from "@panwds/react-ui";
import { PANFieldContainer, PANHelpText, PANMutedText } from "../FormElements/styles";
import {PANWDSLabel} from "./PANWDSLabel";
import classNames from "classnames";
import {SelectItems} from '../../types'
import {find} from 'lodash';
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
    error: {
        border: '1px solid #D13C3C',
        borderRadius: 4,
    },
    errorText: {
        color: "#D13C3C",
    },
    buttonText: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        width: "100%",
        textAlign: "left",
    },
    readOnly: {
        "--tw-bg-opacity": 1,
        backgroundColor: "rgb(244 245 245/var(--tw-bg-opacity))",
        opacity: 1,
    },
    button: {
        color: "#333333",
        "&:hover": {
            color: "#333333",
        }
    }
}));

export const PANWDSSelectWithSearch = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
}) => {
    const classes = useStyles();
    const existerror = !!(touched && error);
    let { items = [], title, required, enableMultiSelect, enableArrayInput, dataMetrics = undefined, loading = false, disabled = false, readOnly = false, initValue = [] }: {items: SelectItems[]}  = props;
    let value = inputProps.value.length ? inputProps.value : initValue || [];
    if (typeof value === 'string') {
      value = [{value}]
    }
    let noneOption: any = [];
    if (enableArrayInput) {
        value = value.map((v: string) => ({ value: v }));
    } else {
        // won't work if enableArrayInput is set
        noneOption = props.allowSetNone && items.length ? [{value: "None"}] : [];
    }

    useEffect(() => {
        if (initValue) {
            if (enableArrayInput) {
                inputProps.onChange(value.map((item) => item.value))
            } else {
                inputProps.onChange(value.value);
            }
        }
      }, [])

    return <PANFieldContainer>
        {title && <PANWDSLabel disabled={disabled} required={required} title={title}/>}
        <SelectWithSearch
            fitToItemWidth
            //@ts-ignore
            itemToString={(item) => (item && item.text) ? `${item?.text} ${item?.value}` : `${item?.value}`}
            items={[...noneOption, ...items]}
            selectedItem={value}
            onChange={({ selectedItem, selectedItems }) => {
                if (enableArrayInput) {
                    inputProps.onChange(selectedItems.map((item) => item.value))
                } else {
                    inputProps.onChange(selectedItem.value);
                }
            }}
            enableMultiSelect={enableMultiSelect}
            button={
                <Button addClassName={classNames(classes.button, readOnly && classes.readOnly)} isMenu fullWidth dataMetrics={dataMetrics ? dataMetrics + '-button' : title +'-button'}>
                    <span className={classes.buttonText}>
                        {value.length
                            ? value.map((item: any) => {
                                let selectedItem = find(items, function(o)  {return o.value === item.value;});
                                return selectedItem && selectedItem.text ? String(selectedItem.text) : String(selectedItem?.value || item.value)
                            }).join(", ")
                            : "Type to select an item"}
                    </span>
                </Button>
            }
            formatOption={{
                height: (item, defaultSize) => defaultSize * 1.2,
                view: (item) => (
                  <div className="tw-flex tw-gap-2 tw-items-center">
                    {item.text ? item.text : item.value}
                  </div>
                ),
            }}
            fullWidth
            dataMetrics={dataMetrics}
            isLoading={loading}
            disabled={disabled || readOnly}
        />
        {existerror && (<PANHelpText>{error}</PANHelpText>)}
        {props?.muted && (<PANMutedText>{props.muted}</PANMutedText>)}
    </PANFieldContainer>;
};
