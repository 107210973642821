import {createSlice} from '@reduxjs/toolkit'
import * as initialState from "../utils/initialStates";
import {
    compareList,
    create,
    deleteResource,
    describe,
    fetchList,
    fetchListDetails,
    resetListDetails,
    updateResource
} from "../actions";
import Resources from '../utils/enums';
import {isEmpty, last} from "lodash";



const usersAndRolesSlice = createSlice({
    name: Resources.USER,
    initialState: initialState.userAndRolesInitialState,
    reducers: {},
    extraReducers: (builder) => {
        const resource = Resources.USER;
        builder
            .addCase(fetchListDetails({resource}).pending, (state) => {
                state.listDetails.loading = true;
            })
            .addCase(fetchListDetails({resource}).fulfilled, (state, action) => {
                state.listDetails.loading = false;
                let index: number = Math.floor(100000 + Math.random() * 900000);
                let responseObj = action.payload.data?.UserDetails?.map((user: any) => ({
                    id: index++,
                    ...user,
                }));

                if (action.meta.arg.data?.NextToken) {
                    state.listDetails.data = [...state.listDetails.data, ...responseObj];
                } else {
                    state.listDetails.data = responseObj;
                }

                state.listDetails.total += action.payload.data?.UserDetails?.length;

                // if (action.payload.data?.UserNames?.length > 0) {
                //     state.list.data = action.payload.data?.UserNames;
                // }

                if (!action.meta.arg?.notSaveNextToken) {
                    state.listDetails.nextToken = action.payload.nextToken;
                }
            })
            .addCase(fetchListDetails({resource}).rejected, (state, action) => {
                state.listDetails.loading = false;
                if (!action.payload?.noToast) {
                    state.listDetails.error = action.payload;
                }
            })
            .addCase(fetchList({resource}).fulfilled, (state, action) => {
                state.list.data = action.payload.data.UserNames;
            })
            .addCase(compareList({resource}).fulfilled, (state, action) => {
                state.list.data = action.payload.list;
                state.listDetails.data = action.payload.listDetails;
                state.listDetails.total = action.payload.listDetails.length;
            })
            .addCase(resetListDetails({resource}).fulfilled, (state, action) => {
                state.listDetails = initialState.listDetails;
                state.list = initialState.list;
            })
            .addCase(describe({resource}).fulfilled, (state, action) => {
                if (action.payload.error) {
                    state.listDetails = initialState.listDetails;
                } else {
                    let userPayload = action.payload.data;
                    // @ts-ignore
                    const lastUserId = last(state.listDetails.data).id;
                    userPayload.id = lastUserId + 1;
                    state.listDetails.data = [...state.listDetails.data, userPayload];
                    state.listDetails.total += 1;
                    if (!isEmpty(state.list.data) && !isEmpty(state.listDetails.data) && state.list.data.length === state.listDetails.total) {
                        const list = state.list.data;
                        const listDetails = state.listDetails.data;
                        const newList: any[] = [];
                        let index: number = Math.floor(100000 + Math.random() * 900000);
                        list.map((l: any) => {
                            const user = listDetails.find((ld: any) => ld.UserName === l);
                            user.id = index++;
                            newList.push(user);
                        })
                        state.listDetails.data = newList;
                    }
                }
            })
            .addCase(describe({resource}).rejected, (state, action) => {
                state.listDetails = initialState.listDetails;
            })
            .addCase(deleteResource({resource}).fulfilled, (state, action) => {
                const deletedItems = action.meta.arg?.deletedItems;
                if (deletedItems && deletedItems.length > 0) {
                    const filteredData = state.listDetails.data.filter((l: any) => deletedItems.findIndex((di: any) => di.UserName === l?.UserName) === -1);
                    state.listDetails.data = filteredData;
                    state.listDetails.total = filteredData.length;
                    state.listDetails.loading = false;
                }
            })
            .addCase(updateResource({resource}).fulfilled, (state, action) => {
                let userPayload = action.payload.data;
                const userName = action.meta.arg.UserName;

                const index = state.listDetails.data.findIndex((d: any) => d.UserName === userName);
                if (index !== -1) {
                    state.listDetails.data[index] = userPayload;
                }
            })
    }
});

export const {} = usersAndRolesSlice.actions;

export default usersAndRolesSlice.reducer
