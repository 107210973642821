import { FormControl, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { find } from "lodash";
import { FC, useEffect, useState, Fragment } from "react";
import { Field } from "react-final-form";
import { useHistory, withRouter } from "react-router-dom";
import { Button as PANWDSButton } from "@panwds/react-ui";
import * as EndPoints from "../../api/FwaasUriEndPoints";
import {
  PANTile,
  PANTitle,
  SaveButton,
    toast
} from "../../components";
import { Row } from "../../components/FormElements";
import { dataProvider } from "../../dataProvider";
import {PANWDSInput, PANWDSChipInput, PANWDSForm, PANWDSBreadcrumbs} from "../../components/PANWDSElements";
import { isBlockedDomain, isEmail, isRequired, composeValidators, requiredValidator } from "../../utils/validate";
import { useTranslate } from '../../customHooks';
import {useAppDispatch} from "../../app/hooks";
import {ReduxActions, ReduxResources} from "../../redux";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    flexDirection: "row",
  },
  toolbar: {
    display: "flex",
    gap: theme.spacing(1),
    justifyContent: "end",
    "-webkit-justify-content": "flex-end",
    alignItems: "start",
    boxSizing: "border-box",
    padding: '10px 0',
    minHeight: "initial",
  },
}));

interface UsersCreateToolbarProps {
  submitting: boolean;
  [key: string]: any;
}

const UsersCreateToolbar: FC<UsersCreateToolbarProps> = ({
  submitting,
  ...props
}) => {
  const classes = useStyles();
  const translate = useTranslate();
  const history = useHistory();
  return (
    <div className={classes.toolbar}>
      <PANWDSButton
        size="md"
        appearance="secondary"
        disabled={submitting}
        onClick={() => {
          history.goBack();
        }}
        dataMetrics="cloudngfw-users-create-cancel"
      >
        {translate(`resources.users.fields.Cancel`)}
      </PANWDSButton>
      <SaveButton
        appearance="primary"
        size="md"
        label={translate(`resources.users.fields.Create`)}
        data-metrics={"cloudngfw-users-create-create"}
        submitOnEnter={true}
        loading={submitting}
        dataMetrics="cloudngfw-users-create-save"
        {...props}
      />
    </div>
  );
};

const UsersCreate = (props: any) => {
  const classes = useStyles();
    const dispatch = useAppDispatch();
  const translate = useTranslate();
  const history = useHistory();
  const [roleChoice, setRoleChoice] = useState<any>([]);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      dataProvider.get("permissionpolicies"),
      dataProvider.get("xaccountroles"),
    ])
      .then((values: any) => {
        const allowedRoles = values[0]?.data?.Permissions || [];
        const accountIds = (values[1]?.data?.AccountIds as string[]) || [];
        const allowedRolesChoice: object[] = [];
        const gra = !!(allowedRoles && find(allowedRoles, {Policy: "GlobalRuleStackAdmin"}));

        allowedRoles.forEach((item: any) => {
          if (
            item?.AccountIdParam === "Mandatory" &&
            gra &&
            item?.Policy === "LocalRuleStackAdmin"
          ) {
          } else if (item?.AccountIdParam === "Mandatory") {
            accountIds.forEach((account: string) => {
              allowedRolesChoice.push({
                id: {
                  AccountId: account,
                  Policy: item?.Policy,
                },
                name: `${account}/${item?.Policy}`,
              });
            });
          } else {
            allowedRolesChoice.push({
              id: {
                Policy: item?.Policy,
              },
              name: item?.Policy,
            });
          }
        });
        if (gra) {
          accountIds.forEach((account: string) => {
            allowedRolesChoice.push({
              id: {
                AccountId: account,
                Policy: "LocalRuleStackAdmin",
              },
              name: `${account}/LocalRuleStackAdmin`,
            });
          });
        }
        setRoleChoice(allowedRolesChoice);
        setLoading(false);
      })
      .catch((error: any) => {
        toast.error(error?.error, { toastId: "permission-accounts-get" });
      });
  }, []);

    const saveUser = async (values: any) => {
        setSubmitting(true);
        await dispatch(ReduxActions.create({resource: ReduxResources.USER})({data: values})).unwrap()
            .then((response) => {
                toast.success("User created successfully");
                history.goBack();
            })
            .catch(error => {
                setSubmitting(false);
                toast.error(error?.error, {toastId: "users-create"});
            });
    };

  const handleChipInputFormatter = (value: any) => {
    const values = value.split("/");
    let o: any = {};
    if (values.length > 1) {
      o.AccountId = values[0];
      o.Policy = values[1];
    } else {
      o.Policy = values[0];
    }
    return o;
  };

  const handleChipValueFormat = (v: any) =>
    v?.AccountId ? `${v?.AccountId}/${v?.Policy}` : `${v?.Policy}`;

  return (
    <>
      <PANTitle divider={false} />
      <PANWDSBreadcrumbs
        mapping={{
          users: translate(`resources.users.name`),
          create: translate(`resources.users.fields.CreateButton`),
        }}
      />
      <PANTitle
        title={translate(`resources.users.fields.CreateButton`)}
        divider
      />
        <PANWDSForm
            toolbar={<UsersCreateToolbar submitting={submitting} />}
            onSubmit={saveUser}
            style={{ margin: 16 }}
        >
            <Fragment>
                <Grid container style={{ width: "auto" }}>
                    <PANTile size={12}>
                        <Row>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <Field
                                        title={translate(`resources.users.fields.FirstName`)}
                                        dataMetrics="cloudngfw-users-create-field-first-name"
                                        name="FirstName"
                                        // @ts-ignore
                                        component={PANWDSInput}
                                        validate={requiredValidator(translate('validation.required'))}
                                        required
                                    />
                                </FormControl>
                            </Grid>
                        </Row>
                        <Row>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <Field
                                        name="LastName"
                                        title={translate(`resources.users.fields.LastName`)}
                                        dataMetrics="cloudngfw-users-create-field-last-name"
                                        // @ts-ignore
                                        component={PANWDSInput}
                                        validate={requiredValidator(translate("validation.required"))}
                                        required
                                    />
                                </FormControl>
                            </Grid>
                        </Row>
                        <Row>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <Field
                                        name="UserName"
                                        title={translate(`resources.users.fields.UserName`)}
                                        dataMetrics="cloudngfw-users-create-field-email"
                                        // @ts-ignore
                                        component={PANWDSInput}
                                        validate={composeValidators(
                                            isRequired,
                                            isBlockedDomain,
                                            isEmail
                                        )}
                                        required
                                    />
                                </FormControl>
                            </Grid>
                        </Row>
                        <Row>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <Field
                                        name="Permissions"
                                        format={(value) => value?.map(handleChipValueFormat)}
                                        row
                                        // @ts-ignore
                                        component={PANWDSChipInput}
                                        title={translate(`resources.users.fields.Roles`)}
                                        items={roleChoice?.map((rc: any) =>
                                            handleChipValueFormat(rc?.id)
                                        )}
                                        loading={loading}
                                        enableArrayInput
                                        formatter={handleChipInputFormatter}
                                        dataMetrics={"cloudngfw-users-create-permissions"}
                                    />
                                </FormControl>
                            </Grid>
                        </Row>
                    </PANTile>
                </Grid>
            </Fragment>
        </PANWDSForm>
    </>
  );
};

export default withRouter(UsersCreate);
