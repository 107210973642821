import cx from "classnames";
import {differenceWith, get, isEmpty, isEqual} from "lodash";
import CircularLoader from "../../../../components/CircularLoader/CircularLoader";
import {Form} from "react-final-form";
import {useEffect, useState} from "react";
import {toast} from "../../../../components";
import {PANWDSModal} from "../../../../components/PANWDSElements";
import {Button, LoadingButton, Button as PANWDSButton, Accordion} from "@panwds/react-ui";
import {dataProvider} from "../../../../dataProvider";
import {usePermissions, useTranslate} from "../../../../customHooks";
import {ReduxActions, ReduxResources} from "../../../../redux";
import {useHistory, useParams} from "react-router-dom";
import {useAppDispatch} from "../../../../app/hooks";
import {FirewallEditComponent} from "../index";

const Index = ({record, classes}: {record: any, classes: any}) => {

    const history = useHistory();
    const dispatch = useAppDispatch();
    const { permissions } = usePermissions();
    const translate = useTranslate();
    const params = useParams<{ firewallname: string }>();
    const firewallName = params.firewallname;


    const [fwEditKey, setFwEditKey] = useState(0);
    const [submitting, setSubmitting] = useState(false);

    const onSubmit = async (values: any, form: any) => {
        values['RuleStackEntry'] = values.RuleStackCandidate;
        let formState = form.getState();

        if (formState.dirty) {
            let calls = [];
            /*need changes for CMLinkId */
            if ((get(formState, "dirtyFields")['Firewall.LinkId'] || get(formState, "dirtyFields")['Firewall.CMLinkId']) && permissions?.AssociateRuleStack) {
                let isLinkIdPanorama = get(formState, "dirtyFields")['Firewall.LinkId']
                setSubmitting(true);
                let payload = {
                    Unlink: isLinkIdPanorama ? (values?.Firewall?.LinkId === "None") : (values?.Firewall?.CMLinkId === "None"),
                    LinkId: isLinkIdPanorama ? values.Firewall.LinkId : values?.Firewall?.CMLinkId,
                    FirewallName: values ? values.Firewall.FirewallName : firewallName,
                    AccountId: values.Firewall.AccountId
                };
                //@ts-ignore
                calls.push(dataProvider.update("firewalls", payload));
                // dataProvider.update("firewalls", payload).then(async (response: any) => {
                //     history.goBack();
                // }).catch((e: any) => {
                //     setFwEditKey(fwEditKey + 1);
                //     toast.error(e?.error, { toastId: "firewalls-update" });
                // }).finally(() => {
                //     setSubmitting(false)
                // });
            }
            if (get(formState, "dirtyFields")['Firewall.RuleStackName'] && permissions?.AssociateRuleStack) {
                let requestBody = (
                    values?.Firewall?.RuleStackName === "None"
                        ? { "Disassociate": true, ...values.Firewall }
                        : { "Associate": true, ...values.Firewall }
                );
                setSubmitting(true);
                calls.push(dataProvider.update("firewalls", requestBody));
                // dataProvider.update("firewalls", requestBody).then(async (response: any) => {
                //     history.goBack();
                // }).catch((e: any) => {
                //     setFwEditKey(fwEditKey + 1);
                //     toast.error(e?.error, { toastId: "firewalls-update" });
                // }).finally(() => {
                //     setSubmitting(false)
                // });
            }
            if (get(formState, "dirtyFields")['Firewall.Description']) {
                if (!permissions?.UpdateFirewallDescription) {
                    toast.warning(translate("permissions.cantExecute"));
                    return;
                }

                let payload = {
                    Description: values.Firewall.Description,
                    FirewallName: values ? values.Firewall.FirewallName : firewallName,
                    AccountId: values.Firewall.AccountId
                };
                //@ts-ignore
                calls.push(dataProvider.update("firewalls", payload));
            }
            if (get(formState, "dirtyFields")['Firewall.SubnetMappings'] &&
                get(formState, "dirtyFields")['Firewall.MultiVpcEnable']) {

                let payload = {
                    AssociateSubnetMappings: permissions?.AssociateSubnetMappings ? differenceWith(values.Firewall.SubnetMappings, formState.initialValues.Firewall.SubnetMappings, isEqual) : [],
                    DisassociateSubnetMappings: permissions?.DisassociateSubnetMappings ? differenceWith(formState.initialValues.Firewall.SubnetMappings, values.Firewall.SubnetMappings, isEqual) : [],
                    FirewallName: values ? values.Firewall.FirewallName : firewallName,
                    AccountId: values.Firewall.AccountId
                };
                //@ts-ignore
                calls.push(dataProvider.update("firewalls", { ...payload, MultiVpcEnable: values?.Firewall?.MultiVpcEnable }));
            } else {
                if (get(formState, "dirtyFields")['Firewall.SubnetMappings']) {

                    let payload = {
                        AssociateSubnetMappings: permissions?.AssociateSubnetMappings ? differenceWith(values.Firewall.SubnetMappings, formState.initialValues.Firewall.SubnetMappings, isEqual) : [],
                        DisassociateSubnetMappings: permissions?.DisassociateSubnetMappings ? differenceWith(formState.initialValues.Firewall.SubnetMappings, values.Firewall.SubnetMappings, isEqual) : [],
                        FirewallName: values ? values.Firewall.FirewallName : firewallName,
                        AccountId: values.Firewall.AccountId
                    };
                    payload.AssociateSubnetMappings  = payload.AssociateSubnetMappings.map((zone: any) => {
                        //return {"AvailabilityZone": zone.AvailabilityZone.split(" ")[0], "AvailabilityZoneId": zone.AvailabilityZone.split(" ")[1].replace('(', '').replace(')', '')};
                        return {"AvailabilityZone": zone.AvailabilityZone.split(" ")[0]};
                    });
                    payload.DisassociateSubnetMappings  = payload.DisassociateSubnetMappings.map((zone: any) => {
                        //return {"AvailabilityZone": zone.AvailabilityZone.split(" ")[0], "AvailabilityZoneId": zone.AvailabilityZone.split(" ")[1].replace('(', '').replace(')', '')};
                        return {"AvailabilityZone": zone.AvailabilityZone.split(" ")[0]};
                    });
                    //@ts-ignore
                    calls.push(dataProvider.update("firewalls", payload));
                }
                if (get(formState, "dirtyFields")['Firewall.MultiVpcEnable']) {
                    if (!permissions?.AssociateSubnetMappings) {
                        toast.warning(translate("permissions.cantExecute"));
                        return;
                    }
                    let payload = {
                        MultiVpcEnable: values.Firewall.MultiVpcEnable,
                        FirewallName: values ? values.Firewall.FirewallName : firewallName,
                        AccountId: values.Firewall.AccountId
                    };
                    //@ts-ignore
                    calls.push(dataProvider.update("firewalls", payload));
                }
            }
            if (get(formState, "dirtyFields")['Firewall.Tags']) {
                const initialValues = formState.initialValues.Firewall.Tags || [];
                const initialTags: any = {};
                initialValues.map((tag: any) => {
                    initialTags[tag.Key] = tag.Value;
                });
                const currentKeys = values.Firewall.Tags.map((tag: any) => tag.Key);
                const newTags = values.Firewall.Tags.filter((tag: any) => !(initialTags[tag.Key] && initialTags[tag.Key] === tag.Value));
                const deleteTags = initialValues
                    .filter((tag: any) => !currentKeys.includes(tag.Key))
                    .map((tag: any) => tag.Key);
                if (newTags.length) {
                    const newTagsPayload = {
                        resourceType: 'ngfirewalls',
                        resourceName: values.Firewall.FirewallName,
                        payload: { "Tags": newTags },
                        AccountId: values.Firewall.AccountId
                    };
                    //@ts-ignore
                    calls.push(dataProvider.update("tags", newTagsPayload));
                }
                if (deleteTags.length) {
                    const deleteTagsPayload = {
                        resourceType: 'ngfirewalls',
                        resourceName: values.Firewall.FirewallName,
                        payload: { "TagKeys": deleteTags },
                        AccountId: values.Firewall.AccountId
                    };
                    //@ts-ignore
                    calls.push(dataProvider.delete("tags", deleteTagsPayload));
                }
            }
            if (calls.length !== 0) {
                setSubmitting(true);
                Promise.all(calls).then((response: any) => {
                    dispatch(ReduxActions.updateResource({resource: ReduxResources.FIREWALL})({
                        FirewallName: values ? values.Firewall.FirewallName : firewallName,
                        AccountId: values.Firewall.AccountId
                    }));
                    if (response[0] && response[0].data) {
                        history.goBack();
                    } else {
                        setFwEditKey(fwEditKey + 1);
                        toast.error(response?.error, { toastId: "firewall-edit" });
                    }
                }).catch((e: any) => {
                    {/*TODO:Need to remove this logic from here*/}
                    // dispatch(ReduxActions.updateResource({resource: ReduxResources.FIREWALL})({
                    //     FirewallName: values ? values.Firewall.FirewallName : firewallName,
                    //     AccountId: values.Firewall.AccountId
                    // }));
                    toast.error(e?.error, { toastId: "firewall-edit" });
                    history.goBack();
                    //setFwEditKey(fwEditKey + 1);
                }).finally(() => {
                    setSubmitting(false);
                });
            }
        }
    };

    const Toolbar = (toolbarProps: any) => {
        const { pristine } = toolbarProps;
        const canEdit = permissions.UpdateFirewallDescription;

        return (
            <div className={classes.toolbar}>
                <PANWDSButton
                    size="md"
                    appearance="secondary"
                    disabled={submitting || pristine}
                    onClick={() => history.goBack()}
                    dataMetrics="cloudngfw-firewall-edit-cancel-button"
                >
                    Cancel
                </PANWDSButton>
                <LoadingButton
                    dataResult={{
                        loading: !!submitting,
                        success: true
                    }}
                    size="md"
                    appearance="primary"
                    type="submit"
                    disabled={submitting || pristine || !canEdit}
                    dataMetrics="cloudngfw-firewall-edit-save-button"
                >
                    Save
                </LoadingButton>
            </div>
        );
    };

    return (
        <div className={cx(isEmpty(record) || submitting ? classes.loadingBlur : "")}>
            <CircularLoader loading={isEmpty(record) || submitting} />
            <Form
                key={fwEditKey}
                onSubmit={onSubmit}
                initialValues={record}
                render={formProps => {
                    let { handleSubmit, values } = formProps;
                    const [confirmDisassociateModal, setConfirmDisassociateModal] = useState({ open: false, callback: () => { } });
                    const [confirmationModal, setConfirmationModal] = useState({ open: false, callback: () => { } });
                    const preSubmit = (event: any) => {
                        event.preventDefault()
                        if (values?.Firewall?.RuleStackName === "None" && !record?.Firewall?.GlobalRuleStackName) { // disassociate workflow
                            setConfirmDisassociateModal({
                                open: true,
                                callback: () => handleSubmit({ ...values })
                            });
                        } else if(values.PolicyManaged === "Strata" && values?.Firewall?.CMLinkId === "None") {
                            setConfirmationModal({
                                open: true,
                                callback: () => handleSubmit({ ...values })
                            });
                        } else { // normal update workflow
                            handleSubmit(values)
                        }
                    }

                    return (
                        <>
                            <form onSubmit={preSubmit} defaultValue={values}>
                                <Accordion
                                    addClassName={classes.pantileContainer}
                                    allowMultipleOpen
                                    allowNoneOpen
                                    onChangeOpenPanels={function Ga() {}}
                                >
                                    <FirewallEditComponent.FirewallSettingsComponent.General
                                        record={record}
                                    />
                                    {process.env.REACT_APP_ENABLE_EGRESS_NAT ===
                                        "true" && (
                                        <FirewallEditComponent.FirewallSettingsComponent.EgressNat />
                                    )}
                                    <FirewallEditComponent.FirewallSettingsComponent.PolicyManagement
                                        record={record}
                                    />
                                    <FirewallEditComponent.FirewallSettingsComponent.EndpointManagement
                                        record={record}
                                    />
                                </Accordion>
                                <Toolbar {...formProps} />
                            </form>
                            <PANWDSModal
                                title={translate(
                                    `resources.firewalls.modal.disassociateModalTitle`
                                )}
                                onClose={() =>
                                    setConfirmDisassociateModal({
                                        open: false,
                                        callback: () => {},
                                    })
                                }
                                isOpen={confirmDisassociateModal.open}
                                size="sm"
                                dataTestId="confirm-rs-disassociate-modal"
                                dataMetrics="confirm-rs-disassociate-modal"
                                modalBody={
                                    <div className={classes.modalBody}>
                                        {translate(
                                            `resources.firewalls.modal.disassociateModalDescription`
                                        )}
                                    </div>
                                }
                                modalFooter={{
                                    footerRequired: true,
                                    cancelButtonRequired: true,
                                    featureSpecificButton: (
                                        <Button
                                            appearance="primary"
                                            onClick={() =>
                                                confirmDisassociateModal.callback()
                                            }
                                            dataMetrics="firewall-disassociate-rs-confirm-btn"
                                        >
                                            {translate(
                                                `resources.firewalls.modal.confirm`
                                            )}
                                        </Button>
                                    ),
                                }}
                            />
                            <PANWDSModal
                                title={translate(
                                    `resources.firewalls.modal.warning`
                                )}
                                onClose={() =>
                                    setConfirmationModal({
                                        open: false,
                                        callback: () => {},
                                    })
                                }
                                isOpen={confirmationModal.open}
                                size="sm"
                                dataTestId="cloudngfw-scm-no-link-modal"
                                dataMetrics="cloudngfw-scm-no-link-modal"
                                modalBody={
                                    <div className={classes.modalBody}>
                                        {translate(
                                            `resources.firewalls.modal.scmConfirm`
                                        )}
                                    </div>
                                }
                                modalFooter={{
                                    footerRequired: true,
                                    cancelButtonRequired: true,
                                    featureSpecificButton: (
                                        <Button
                                            appearance="primary"
                                            onClick={() =>
                                                confirmationModal.callback()
                                            }
                                            dataMetrics="firewall-disassociate-scm-confirm-btn"
                                        >
                                            {translate(
                                                `resources.firewalls.modal.confirm`
                                            )}
                                        </Button>
                                    ),
                                }}
                            />
                        </>
                    );
                }} />
        </div>
    );
};

export default Index;
