import jwt_decode from "jwt-decode";
import queryString from 'query-string'
import _, { find, kebabCase } from "lodash";
import * as React from 'react';
import { useCallback, useEffect, useState, memo } from 'react';
import {usePermissions, useTranslate} from '../../customHooks';
import { useHistory, withRouter } from 'react-router-dom';
import { PANTitle, IntervalCallerComponent, toast } from "../../components";
import { dataProvider } from "../../dataProvider";
import Emitter from '../../eventEmitter';
import { nameStyleCursor } from '../../layout/styles';
import { RouteUri } from '../../routeUri';
import { PANWDSTable } from '../../components/PANWDSElements';
import { searchFilters } from "../rules/Utils/searchFilters";
import { Button, Link, Sheet, SheetBody } from '@panwds/react-ui';
//@ts-ignore
import pako from "pako";
//@ts-ignore
import XMLViewer from 'react-xml-viewer';
import { Box, CircularProgress, makeStyles } from "@material-ui/core";
import { downloadContent } from "../../utils/DownloadContent";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {ReduxActions, ReduxResources} from "../../redux";
import isEmpty from "lodash/isEmpty";
import {compareList} from "../../redux/actions";

let localSelectedRegion: string;

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    gap: theme.spacing(1),
    justifyContent: 'end',
    '-webkit-justify-content': 'flex-end',
    alignItems: 'start',
    padding: '10px 0 0 0',
    backgroundColor: 'white',
    borderTop: "1px solid #DADBDB",
  },
  loadingCenter: {
    position: "relative",
    top: "50%",
    left: "50%",
    filter: "brightness(10%)",
    zIndex: 999,
  },
  sheetContainer: {
    height: '100%'
  },
  xmlContainer: {
    padding: '10px',
    background: "#fcfcfc",
    border: "1px solid #dedede",
    height: 'calc(98% - 80px)',
    overflow: 'scroll'
  }
}));

const RuleStackList = memo((props: any) => {
  const nameClass = nameStyleCursor();
    const dispatch = useAppDispatch();
    const ruleStacksState = useAppSelector((state) => state.ruleStacks);

  const { permissions, rawPermissions } = usePermissions();

    const [gridData, setGridData] = useState<never[]>(ruleStacksState.listDetails.data);
    const [loading, setLoading] = useState(ruleStacksState.listDetails.loading);
  const [nextToken, setNextToken] = useState<any>();
  const translate = useTranslate();
  const history = useHistory();
  const rsCommiting = gridData.filter((rs: any) => rs.RuleStackState === "Committing");
  const [searchString, setSearchString] = useState<any | ''>('');

  const [showXMLModal, setShowXMLModal] = useState<string | undefined>(undefined);
  const [xmlRecords, setXmlRecords] = useState<any>({});

  useEffect(() => {
    const value = queryString.parse(props?.location?.search);
    const search = value?.search ? value?.search : '';
    setSearchString(search?.toString())
  }, [gridData]);

  const updateCommitingRS = () => {
    const MAX_REFRESH_CALLS = 10;
    const committingRulestacks = gridData.filter((rs: any) => rs.RuleStackState === "Committing").slice(0, MAX_REFRESH_CALLS);

    Promise.all(committingRulestacks.map((rs: any) => dataProvider.describe('ruleStacks', '', { RuleStackName: rs.RuleStackName })))
      .then((responses: any) => {
        let updatedRS = responses.filter((res: any) => res.data.RuleStackState !== "Committing");
        if (updatedRS.length > 0) {
          let newGrid: any = [];
          gridData.map((record: any) => {
            updatedRS.map((res: any) => {
              if (record.RuleStackName === res.data.RuleStackName) {
                record.RuleStackState = res.data.RuleStackState;
              }
            });
            newGrid.push(record);
          });
          //@ts-ignore
          setGridData([...newGrid]);
        }
      });
  };

    useEffect(() => {
        setGridData(ruleStacksState.listDetails.data);
        setNextToken(ruleStacksState.listDetails.nextToken);
        if (!ruleStacksState.listDetails.loading && loading) {
            setLoading(false);
            Emitter.emit("regionLoadingData", false);
        }
        if (ruleStacksState.listDetails.error) {
            toast.error(ruleStacksState.listDetails.error.error, { toastId: `rulestack-list-error` });
        }
    }, [ruleStacksState.listDetails.data, ruleStacksState.listDetails.loading]);

  const loadGridData = (needRefresh = false) => {
    if (!permissions?.ListRuleStacks) {
      if (permissions && !permissions?.ListRuleStacks) {
        // TODO toast is not showing, but if we add toast container it will show
        // many times until permissions is loaded, need to rethink this logic
        toast.warning(translate("permissions.cantView"));
      }
      setLoading(false);
      return;
    }
    if (!gridData.length || (gridData.length && nextToken) || needRefresh) {
      if (!gridData.length || needRefresh) {
        setGridData([]);
        setLoading(true);
        Emitter.emit("regionLoadingData", true);
      }

        dispatch(ReduxActions.fetchListDetails({resource: ReduxResources.RULESTACK})({ data: { NextToken: nextToken ?? undefined } }));

      // dataProvider.getList("ruleStacks", { data: { NextToken: nextToken ?? undefined } })
      //   .then((response) => {
      //     if (response.data) {
      //       // @ts-ignore
      //       setGridData((prev) => ([...(needRefresh ? [] : prev), ...response.data]));
      //       setNextToken(response?.nextToken);
      //     } else {
      //       toast.error(response?.error, { toastId: `rulestack-list-error` });
      //     }
      //   })
      //   .catch((error: any) => {
      //     if (error?.noToast) {
      //       return;
      //     }
      //     toast.error(error?.error, { toastId: `rulestack-list-error` });
      //   })
      //   .finally(() => {
      //     setLoading(false);
      //     Emitter.emit("regionLoadingData", false);
      //   });
    } else {
        dispatch(ReduxActions.compareList({resource: ReduxResources.RULESTACK})({}));
      // console.log("Something happens", gridData);
    }
  };

    const handleRegionChange = (region: any) => {
        if (nextToken) {
            setNextToken(undefined);
        }
        loadGridData(true);
    };

  useEffect(() => {
    //localSelectedRegion = selectedRegion?.RegionCode;
    Emitter.on('regionChanged', handleRegionChange);
    return () => {
      Emitter.off('regionChanged', handleRegionChange);
      dataProvider.abort('ruleStacks');
    }
  }, []);

  const getcolumns = () => [
    {
      accessor: 'RuleStackName',
      name: 'RuleStackName',
      Header: translate(`resources.ruleStacks.fields.RulestackName`),
      columnSize: 6,
      render: ({ row }: any) => {
        if (row?.original?.RuleStack.ReadOnly) {
          return <span
            data-test-id={`rulestack-record-${kebabCase(row?.original?.RuleStackName.toLowerCase())}`}
          >
            {row?.original?.RuleStackName}
          </span>
        } else {
          return (
            <span
              className={nameClass.blueColor}
              data-test-id={`rulestack-record-${kebabCase(row?.original?.RuleStackName.toLowerCase())}`}
              onClick={(evt) => onRowClick(row, evt)}
            >
              {row?.original?.RuleStackName}
            </span>
          );
        }
      }
    },
    {
      accessor: 'RuleStackState',
      name: 'RuleStackState',
      Header: translate(`resources.ruleStacks.fields.RuleStackState`),
      columnSize: 2,
      render: ({ row, column }: any) => <span data-test-id={`rulestack-record-${column?.columnIndex}-${row?.id}`}>{row?.original?.RuleStackState}</span>
    },
    {
      accessor: 'RuleStack.Scope',
      name: 'RuleStack.Scope',
      id: 'Type',
      Header: translate(`resources.ruleStacks.fields.Scope`),
      columnSize: 1,
      render: ({ row, column }: any) => <span data-test-id={`rulestack-record-${column?.columnIndex}-${row?.id}`}>{row?.original?.RuleStack.Scope}</span>
    },
    {
      accessor: 'RuleStack.AccountId',
      name: 'RuleStack.AccountId',
      Header: translate(`resources.ruleStacks.fields.AccountId`),
      columnSize: 1,
      render: ({ row, column }: any) => <span data-test-id={`rulestack-record-${column?.columnIndex}-${row?.id}`}>{row?.original?.RuleStack.AccountId}</span>
    },
    {
      accessor: 'action',
      name: 'action',
      Header: translate(`resources.ruleStacks.fields.Action`),
      columnSize: 1,
      render: ({ row, column }: any) => <span data-test-id={`rulestack-record-${column?.columnIndex}-${row?.id}`}>
        {row?.original?.RuleStack.ReadOnly ? <Link size="sm" onClick={(evt) => setShowXMLModal(row?.original?.RuleStackName)}>View XML</Link> : undefined}
      </span>
    }
  ];

  const deleteAction = (selected: any) => {
    if (!permissions?.DeleteRuleStack) {
      toast.warning(translate("permissions.cantExecute"));
      return;
    }
    if (selected && Array.isArray(selected)) {
      setLoading(true);
      const successItems: any = [];
      const failedItems: any = [];
      const commitedSuccess: any = [];
      let pendingCalls = 0;
      selected.map((item: any) => {
          dataProvider.delete("ruleStacks", { id: item.RuleStackName, previousData: item })
              .then(async (response: any) => {
                  successItems.push(item);
                  pendingCalls += 1;
                  dataProvider.commit("ruleStacks", {ruleStackName: item.RuleStackName})
                      .then(async (response: any) => {
                          if (response.data) {
                              commitedSuccess.push(item)
                          } else {
                              // toast.error(response?.error);
                          }
                      })
                      .catch((e: any) => {
                          toast.error(e?.error);
                      })
                      .finally(() => {
                          pendingCalls -= 1;
                          DeleteAndNotify(selected, successItems, failedItems, commitedSuccess, pendingCalls);
                      });
              })
              .catch((e: any) => failedItems.push({item, e}))
              .finally(() => DeleteAndNotify(selected, successItems, failedItems, commitedSuccess, pendingCalls));
          return item;
      })
    }
  };

  const DeleteAndNotify = (selected: any, successItems: any, failedItems: any, commitedSuccess: any, pendingCalls: number) => {
    if (failedItems.length + successItems.length === selected.length && pendingCalls === 0) { // request finished
        dispatch(ReduxActions.deleteResource({resource: ReduxResources.RULESTACK})({deletedItems: successItems}));
      // let ids = successItems.map((item: any) => item.id);
      // setGridData((prev) => ([...prev.filter((record: any) => !ids.includes(record.id))]));
      //setLoading(false);
      if (successItems.length > 0) {
        let message = (
          <>
            Rulestacks deleted succesfully:
            <ul>
              {successItems.map((item: any) => <li> - {item.RuleStackName}</li>)}
            </ul>
            {(commitedSuccess.length > 0) ? (
              <>
                <br />
                Running Rulestacks commited succesfully:
                <ul>
                  {commitedSuccess.map((item: any) => <li> - {item.RuleStackName}</li>)}
                </ul>
              </>
            ) : null
            }
          </>
        );
        toast.success(message);
      }
      if (failedItems.length > 0) {
        toast.error(<>
          Couldn't delete the following Rulestack(s):
          {<ul>
            {failedItems.map(({ item, e }: any) => <li> - {item.RuleStackName} ({e?.error?.error})</li>)}
          </ul>}
        </>);
      }
    }
  };

  const actionsMap: any[] = [];

  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  ((rawPermissions && find(rawPermissions, { 'Policy': 'GlobalRuleStackAdmin' }))) ? actionsMap.push({ menuText: "Global", dataTestId: "rulestack-create-btn-global", handleAction: () => { history.push(RouteUri.RuleStackCreate + "?scope=Global") }, dataMetrics: "cloudngfw-rulestack-create-btn-global" }) : "";

  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  ((rawPermissions && find(rawPermissions, { 'Policy': 'LocalRuleStackAdmin' }))) ? actionsMap.push({ menuText: "Local", dataTestId: "rulestack-create-btn-local", handleAction: () => { history.push(RouteUri.RuleStackCreate + "?scope=Local") }, dataMetrics: "cloudngfw-rulestack-create-btn-local" }) : "";

  const toolbarActions: any[] = [];
  if(permissions?.DeleteRuleStack) {
    toolbarActions.push({
      title: translate(`common.actions`),
      dataMetrics: "cloudngfw-rulestack-actions-btn",
      actionsMap: [{ menuText: translate(`common.delete`), handleAction: deleteAction, confirmModal: 'delete', dataMetrics: "cloudngfw-rulestack-delete-btn", },]
    });
  }
  if (permissions?.CreateRuleStack && actionsMap.length > 0) {
    toolbarActions.push({
      title: "Create Rulestack",
      dataMetrics: "cloudngfw-rulestack-create-btn",
      actionsMap: actionsMap,
      dataTestId: "cloudngfw-rulestack-create-btn",
      disabled: !(permissions?.CreateRuleStack),
      type: 'primary',
      appearance: 'primary',
    });
  }

  const onRowClick = useCallback((rowProps: any, event) => {
    if (!permissions?.DescribeRuleStack) {
      return;
    }
    history.push(RouteUri.RuleList.replace(":rulestackname", rowProps.original.RuleStackName)
      + "?AccountId=" + rowProps.original.RuleStack.AccountId);
  }, [history, permissions])

  const RSXmlModal = ({ close, rsName, data, isOpen }: any) => {
    const classes = useStyles();
    const [xmlData, setXmlData] = useState("");
    const handleDownload = () => {
      downloadContent("rulestack-export.xml", xmlData as string);
      close();
    }

    const loadXMLData = () => {
      dataProvider.getXml('ruleStacks', { RuleStackName: rsName })
        .then((response: any) => {
          // Decode base64 (convert ascii to binary)
          var strData = window.atob(response.data.RuleStackXml.Xml);
          // Convert binary string to character-number array
          var charData = strData.split('').map(function (x) { return x.charCodeAt(0); });
          // Turn number array into byte-array
          var binData = new Uint8Array(charData);
          // Pako magic
          var data = pako.inflate(binData);
          // @ts-ignore
          let intData = new Uint16Array(data);
          let result = "";
          intData.forEach((n) => {
            result += String.fromCharCode(n);
          });
          setXmlData(eval(result));
        });
    }

    useEffect(() => {
      if (!data[rsName] && rsName) {
        loadXMLData();
      } else {
        setXmlData(data[rsName]);
      }
    }, []);

    const MAX_XML_LENGTH = 10000;
    const showXMLViewer = () => {
      return process.env.REACT_APP_USE_XML_VIEWER === "yes" ? <XMLViewer xml={xmlData} /> : <pre lang="xml" >{xmlData}</pre>;
      // if (xmlData.length > MAX_XML_LENGTH) {
      //   return <pre lang="xml" >{xmlData}</pre>;
      // } else {
      // return process.env.REACT_APP_USE_XML_VIEWER === "yes" ? <XMLViewer xml={xmlData} /> : <pre lang="xml" >{xmlData}</pre>;
      // }
    }

    return (<>
      <Sheet
        onClose={() => close(rsName, xmlData)}
        title="View XML"
        isOpen={isOpen}
        position="xl"
        showMask={true}
      >
        <SheetBody addClassName={classes.sheetContainer}>
          <Box className={classes.xmlContainer}>
            {xmlData && showXMLViewer()}
            {!xmlData && <div className={classes.loadingCenter}>
              <CircularProgress size={25} thickness={4} />
            </div>}
          </Box>
          <Box className={classes.toolbar}>
            <Button
              size="md"
              appearance="secondary"
              onClick={() => close()}
            >{"Cancel"}</Button>
            <Button
              size="md"
              appearance="primary"
              onClick={() => handleDownload()}
              disabled={!xmlData}
            >{"Download XML"}</Button>
          </Box>
        </SheetBody>
      </Sheet>
    </>);
  };

  const handleClose = (rsName: string, xmlData: string) => {
    setXmlRecords({ ...xmlRecords, [rsName]: xmlData });
    setShowXMLModal(undefined);
  };

  return (
    <div>
      <PANTitle region divider
        title={translate(`resources.ruleStacks.name`)}
        paddingContainer="16px"
      />
      <PANWDSTable
        loadGridData={loadGridData}
        infiniteScroll={false}
        overflowTable={true}
        permissions={permissions}
        columns={getcolumns()}
        gridData={gridData}
        showSelectGroupBy={false}
        title={translate(`resources.ruleStacks.name`)}
        subtitle={translate(`resources.ruleStacks.fields.ListSubtitle`)}
        idProperty={"RuleStackName"}
        showToolbar={true}
        dropDownActionsArray={toolbarActions}
        showTileTitle={true}
        emptyTitle={translate(`resources.ruleStacks.fields.EmptyTitle`)}
        emptySubtitle={translate(`resources.ruleStacks.fields.EmptySubtitle`)}
        loading={loading}
        isBackgroundFetching={nextToken}
        singleSelect={false}
        multiSelect={true}
        dataTestId="rulestacks-list-table"
        offsetTableHeight={215}
        tableMinHeight={600}
        filterBar={{
          filterBarRequired: true,
          onlySearchFilterBarRequired: true,
          filterConfig: searchFilters(searchString)
        }}
        dataMetrics="cloudngfw-rulestack-table"
        /* disableRow={(row : any) => {
          if (row && _.has(row, ['row', 'original', 'RuleStack', 'ReadOnly'])) {
            return row.row.original.RuleStack.ReadOnly;
          }
          return false;
        }} */
        disableActionsBtn={(rowsSelected: any) => { return rowsSelected.filter((row: any) => row.RuleStack.ReadOnly).length > 0; }}
      />
      {rsCommiting.length > 0 ? <IntervalCallerComponent updateCallback={updateCommitingRS} /> : null}
      <RSXmlModal close={handleClose} rsName={showXMLModal} data={xmlRecords} isOpen={showXMLModal} />
    </div>
  );

});

export default withRouter(RuleStackList);
