import { Typography } from "@material-ui/core";
import {
    Button as PANWDSButton,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalFooter,
    ModalHeader,
    Tooltip,
} from "@panwds/react-ui";
import { extend } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { usePermissions, useTranslate } from "../../customHooks";
import { useHistory, withRouter } from "react-router-dom";
import { PANTitle, toast } from "../../components";
import CircularLoader from "../../components/CircularLoader/CircularLoader";
import { PANWDSTable } from "../../components/PANWDSElements";
import { dataProvider } from "../../dataProvider";
import { nameStyleCursor } from "../../layout/styles";
import { RouteUri } from "../../routeUri";
import jwt_decode from "jwt-decode";
import { InfoIcon, DownloadIcon, DeleteIcon, PlusIcon } from "@panwds/icons";
import _ from "lodash";
import ManageAccountModal from "../components/ManageAccountModal";

const useStyles = makeStyles((theme) => ({
    iconedParagraph: {
        display: "flex",
        alignItems: "center",
        marginTop: "5px",
        gap: "10px",
    },
}));

const AccountsList = (props: any) => {
    const nameClass = nameStyleCursor();
    const [gridData, setGridData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [accountPropertyDetails, setAccountPropertyDetails] = useState(<></>);
    const [openAccountPropertyModal, setOpenAccountPropertyModal] = useState(false);
    const [openManageXAccountRoleModal, setOpenManageXAccountRoleModal] = useState(false);
    const [upateToken, setUpdateToken] = useState("");
    const [manageXAccountRoleModalData, setManageXAccountRoleModalData] = useState({});

    const classes = useStyles();

    // @ts-ignore
    const [gridRef, setGridRef] = useState({
        current: {
            columns: [],
        },
    });

    const translate = useTranslate();
    const history = useHistory();
    const title = translate(`resources.accounts.name`);

    const { permissions } = usePermissions();

    const mergeArrayOfObjects = (
        xAccountData: any,
        linkAccountData: any,
        selector = "key"
    ) => {
        let result: any[] = [];
        xAccountData.forEach((dat: any) => {
            const foundIndex = linkAccountData.findIndex(
                (ori: any) => ori[selector] === dat[selector]
            );
            if (foundIndex >= 0)
                result.push(extend(dat, linkAccountData[foundIndex]));
            else result.push(dat);
        });

        linkAccountData.forEach((dat: any) => {
            const foundIndex = result.findIndex(
                (ori: any) => ori[selector] === dat[selector]
            );
            if (foundIndex === -1) result.push(dat);
        });
        return result;
    };

    const generateUpdateToken = (data: any) => {
        setLoading(true);
        const payload: any = {
            AccountId: data?.AccountId,
        };
        //@ts-ignore
        dataProvider
            .update("accounts", payload)
            .then((response) => {
                setOpenAccountPropertyModal(false);
                let updateToken = response.data;
                setUpdateToken(updateToken?.UpdateToken);
                setAccountProperty(data, updateToken?.UpdateToken);
            })
            .catch((e: any) => {
                toast.error(e?.error, { toastId: "linkaccount-update-token" });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const loadLinkAccount = (currentData: any[]) => {
        setLoading(true);
        if (!permissions?.ListLinkAccount) {
            if (permissions && !permissions?.ListLinkAccount) {
                // TODO toast is not showing, but if we add toast container it will show
                // many times until permissions is loaded, need to rethink this logic
                toast.warning(translate("permissions.cantView"));
            }
            setLoading(false);
            return;
        }
        Promise.all([
            dataProvider.getList("xaccountroles"),
            dataProvider.getList("accounts"),
        ])
            .then((values: any) => {
                const XAccountDetails = values[0].data.XAccountDetails;
                const AccountDetails = values[1].data.AccountDetails;
                let accountData = mergeArrayOfObjects(
                    XAccountDetails,
                    AccountDetails,
                    "AccountId"
                );
                let index: number = Math.floor(100000 + Math.random() * 900000);
                accountData = accountData.map((data) => ({
                    id: index++,
                    ...data,
                }));
                //@ts-ignore
                setGridData([...accountData]);
                setLoading(false);
            })
            .catch((e: any) => {
                if (e?.noToast) {
                    return;
                }
                toast.error(e?.error, { toastId: "xaccountroles-getList" });
                setLoading(false);
            });
    };

    const getManageAccountModal = () => {
        return (
          <ManageAccountModal
            isOpenTags={openManageXAccountRoleModal}
            setOpenTags={setOpenManageXAccountRoleModal}
            rowData={manageXAccountRoleModalData}
            callBackList={(values: any) => handleSave(values)}
            loading={loading}
          />
        );
    };

    useEffect(() => {
        return () => {
            dataProvider.abort("accounts");
            dataProvider.abort("xaccountroles");
        };
    }, []);

    useEffect(() => {
        loadLinkAccount([]);
    }, [permissions]);

    const getcolumns = () => [
        {
            id: "AccountId",
            accessor: "AccountId",
            Header: translate(`resources.accounts.fields.AccountId`),
            columnSize: 2,
            minWidth: 120,
            render: ({ row }: any) => {
                if (
                    row?.original?.OnboardingStatus === "UnsubscriptionPending"
                ) {
                    return <span>{row?.original?.AccountId}</span>;
                }
                return (
                    <span
                        className={nameClass.blueColor}
                        onClick={(evt) => onRowClick(row, evt)}
                    >
                        {row?.original?.AccountId}
                    </span>
                );
            },
        },
        {
            id: "XAccountIAMRoles",
            accessor: "XAccountIAMRoles",
            Header: translate(`resources.accounts.fields.XAccountIAMRoles`),
            columnSize: 3,
            minWidth: 175,
            noTooltip: true,
            render: ({ row }: any) => {
                let roles = [];
                if (row?.original?.RuleStack?.NetworkMonitoringRole) {
                    roles.push(
                        translate(`resources.accounts.fields.NetworkMonitoringRole`)
                    );
                }
                if (row?.original?.RuleStack?.DecryptionRole) {
                    roles.push(
                        translate(`resources.accounts.fields.DecryptionRole`)
                    );
                }
                if (row?.original?.Firewall?.EndpointRole) {
                    roles.push(
                        translate(`resources.accounts.fields.EndpointRole`)
                    );
                }
                if (row?.original?.Firewall?.LoggingRole) {
                    roles.push(
                        translate(`resources.accounts.fields.LoggingRole`)
                    );
                }
                return (
                    <Tooltip label={roles.join(", ")}>
                        <span>{roles.join(", ")}</span>
                    </Tooltip>
                );
              },
        },
        {
            id: "AccountProperty",
            accessor: "AccountProperty",
            Header: translate(`resources.accounts.fields.AccountProperty`),
            columnSize: 2,
            minWidth: 150,
            noTooltip: true,
            render: ({ row }: any) => (
                <span
                    onClick={() => setAccountProperty(row?.original)}
                    className={nameClass.blueColor}
                >
                    {translate(`resources.accounts.fields.CheckDetails`)}
                </span>
            ),
        },
        {
            accessor: "OnboardingStatus",
            Header: translate(`resources.accounts.fields.OnboardingStatus`),
            columnSize: 2,
            noTooltip: true,
            render: ({ row }: any) => {
                if (row?.original?.OnboardingStatus === "Pending") {
                    return (
                        <a
                            href={row?.original?.CloudFormationTemplateURL}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {row?.original?.OnboardingStatus}
                        </a>
                    );
                } else if (
                    row?.original?.OnboardingStatus === "UnsubscriptionPending"
                ) {
                    return (
                        <div className={classes.iconedParagraph}>
                            <span>{row?.original?.OnboardingStatus} </span>
                            <Tooltip
                                label={
                                    <>
                                        <strong>
                                            {translate(
                                                `resources.accounts.fields.UnsubscribePendingTooltip1`
                                            )}
                                        </strong>
                                        <div>
                                            {translate(
                                                `resources.accounts.fields.UnsubscribePendingTooltip2`
                                            )}
                                        </div>
                                        <div>
                                            {translate(
                                                `resources.accounts.fields.UnsubscribePendingTooltip3`
                                            )}
                                        </div>
                                        <div>
                                            {translate(
                                                `resources.accounts.fields.UnsubscribePendingTooltip4`
                                            )}
                                        </div>
                                    </>
                                }
                            >
                                <InfoIcon size="xs" />
                            </Tooltip>
                        </div>
                    );
                } else {
                    return <span>{row?.original?.OnboardingStatus}</span>;
                }
            },
        },
        {
            accessor: "placeholder",
            Header: translate(`resources.accounts.fields.Actions`),
            columnSize: 2,
            noTooltip: true,
            render: ({ row }: any) => {
                let actions = [];
                actions.push(
                    <Tooltip label={translate(`resources.accounts.fields.ManageXAccountRoles`)}>
                        <div className="tw-cursor-pointer" onClick={() => setXAccountRoleArn(row?.original)}>
                            <PlusIcon
                                className="tw-text-gray-600 dark:tw-text-dark-bg"
                                size="md"
                                data-metrics="cloudngfw-users-manageXAccountRole-btn"
                            />
                        </div>
                    </Tooltip>
                )
                if (row?.original?.CloudFormationTemplateURL) {
                    let key = "templateURL";
                    let start =
                        row?.original?.CloudFormationTemplateURL.indexOf(key);
                    if (start === -1) {
                        return <></>;
                    }
                    start += key.length + 1;
                    let end =
                        row?.original?.CloudFormationTemplateURL.substr(
                            start
                        ).indexOf("&");
                    let cftUrl =
                        row?.original?.CloudFormationTemplateURL.substr(
                            start,
                            end
                        );
                    cftUrl = decodeURIComponent(cftUrl);
                    actions.push(
                        <Tooltip label={translate(`resources.accounts.fields.DownloadCFT`)}>
                            <a
                                href={cftUrl}
                                target="_blank"
                                rel="noreferrer"
                            >
                            <DownloadIcon
                                className="tw-text-gray-600 dark:tw-text-dark-bg"
                                size="md"
                                data-metrics="cloudngfw-users-cft-btn"
                            />
                            </a>
                        </Tooltip>
                    );
                }
                if (
                    !permissions?.AssociateGlobalRuleStack &&
                    permissions?.DeleteLinkAccount
                ) {
                    actions.push(
                        <Tooltip label={translate(`resources.accounts.fields.Delete`)}>
                            <div className="tw-cursor-pointer" onClick={() => deleteAction(row?.original)}>
                                <DeleteIcon
                                    className="tw-text-gray-600 dark:tw-text-dark-bg"
                                    size="md"
                                    data-metrics="cloudngfw-users-delete-btn"
                                />
                            </div>
                        </Tooltip>
                    )
                }
                return <div className="tw-flex tw-items-center tw-gap-4">
                    {actions.map((action, index) => (
                        <div key={index}>
                            {action}
                        </div>
                    ))}
                </div>;
            },
        },
    ];

    const setAccountProperty = (data: any, token: string = "") => {
        setAccountPropertyDetails(
            <>
                <div style={{ wordWrap: "break-word" }}>
                    <p>
                        <Typography variant="body2">External ID:</Typography>
                    </p>
                    <Input allowCopy readOnly value={data?.ExternalId} />
                    <p>
                        <Typography variant="body2">
                            CloudNGFW Account ID:
                        </Typography>
                    </p>
                    <Input allowCopy readOnly value={data?.ServiceAccountId} />
                    <p>
                        <Typography variant="body2">SNS Topic ARN:</Typography>
                    </p>
                    <Input allowCopy readOnly value={data?.SnsTopicArn} />
                    <p>
                        <Typography variant="body2">Token:</Typography>
                    </p>
                    {upateToken || token || data?.UpdateToken ? (
                        <>
                            <Input
                                allowCopy
                                readOnly
                                value={upateToken || token || data?.UpdateToken}
                            />
                            <Typography variant="caption" color="textSecondary">
                                This token is valid until{" "}
                                {
                                    //@ts-ignore
                                    new Date(jwt_decode(upateToken || token || data?.UpdateToken)["exp"] * 1000).toString()
                                }
                            </Typography>
                        </>
                    ) : (
                        <PANWDSButton
                            disabled={!permissions?.CreateUpdateToken}
                            color="secondary"
                            size="sm"
                            onClick={() => generateUpdateToken(data)}
                        >
                            Generate Update Token
                        </PANWDSButton>
                    )}
                </div>
            </>
        );
        setOpenAccountPropertyModal(true);
    };

    const setXAccountRoleArn = (data: any) => {
        setOpenManageXAccountRoleModal(true);
        setManageXAccountRoleModalData(data);
    };

    const deleteAction = (selected: any) => {
        setLoading(true);
        if (selected && Array.isArray(selected)) {
            selected.map((item: any) => {
                if (item?.AccountId) {
                    dataProvider
                        .delete("accounts", {
                            id: item?.AccountId,
                            previousData: item,
                        })
                        .then(async (response: any) => {
                            window.location.reload();
                        })
                        .catch((e: any) => {
                            toast.error(e?.error, {
                                toastId: "accounts-delete",
                            });
                            setLoading(false);
                        });
                }
                return item;
            });
        } else if (selected && selected?.AccountId) {
            dataProvider
                .delete("accounts", {
                    id: selected?.AccountId,
                    previousData: selected,
                })
                .then(async (response: any) => {
                    window.location.reload();
                })
                .catch((e: any) => {
                    toast.error(e?.error, { toastId: "accounts-delete" });
                    setLoading(false);
                });
        }
    };

    const onRowClick = useCallback(
        (rowProps, event) => {
            history.push(
                RouteUri.AccountEdit.replace(":id", rowProps.original.AccountId)
            );
        },
        [history]
    );

    const toolbarActions: any[] = [];
    // { menuText: translate(`resources.accounts.fields.ManageXAccountRoles`), handleAction : setOpenManageXAccountRoleModal(true), dataMetrics: "cloudngfw-users-manageXAccountRole-btn", },
    if (permissions?.CreateLinkAccount) {
        toolbarActions.push({
            title: translate(`resources.accounts.fields.CreateButton`),
            actionsMap: [],
            handleButtonAction: () => history.push(RouteUri.AccountCreate),
            dataMetrics: "cloudngfw-accounts-create-btn",
            type: "button",
            appearance: "primary",
            disabled:
                permissions &&
                permissions.hasOwnProperty("AssociateGlobalRuleStack"),
        });
    }

    const handleSave = (toolBarProps: any) => {
        let payload: any = {}
        if (toolBarProps?.Firewall !== undefined) {
            payload = { Firewall: toolBarProps?.Firewall };
        }
        if (toolBarProps?.RuleStack !== undefined) {
            payload = { ...payload, RuleStack: toolBarProps?.RuleStack };
        }
        setLoading(true);
        dataProvider
            .update("xaccountroles", payload)
            .then(async (response: any) => {
                if (response?.data) {
                    loadLinkAccount([]);
                } else {
                    toast.error(response?.error, {
                        toastId: "xaccountrole-update",
                    });
                }
            })
            .catch((e: any) => {
                toast.error(e?.error?.error, { toastId: "xaccountrole-update" });
            })
            .finally(() => {
                setOpenManageXAccountRoleModal(false);
                setLoading(false);
            });
    };

    const manageAccountModal = getManageAccountModal();

    return (
        <>
            <PANTitle divider title={title} paddingContainer="16px" />
            <PANWDSTable
                setHandle={setGridRef}
                columns={getcolumns()}
                gridData={gridData}
                showSelectGroupBy={false}
                title={title}
                subtitle={translate(`resources.accounts.fields.ListSubtitle`)}
                idProperty={"id"}
                showToolbar={true}
                dropDownActionsArray={toolbarActions}
                showTileTitle={true}
                emptyTitle={translate(`resources.accounts.fields.EmptyTitle`)}
                emptySubtitle={translate(
                    `resources.accounts.fields.EmptySubtitle`
                )}
                loading={loading}
                singleSelect={false}
                multiSelect={false}
                overflowTable={true}
                useOldToolbarButtons={true}
                offsetTableHeight={155}
                tableMinHeight={600}
                searchFilterRequired={true}
                dataMetrics="cloudngfw-account-table"
                dataTestId="cloudngfw-account-table"
                disableActionsBtn={(rowsSelected: any) => {
                    return (
                        rowsSelected.filter(
                            (row: any) =>
                                row.OnboardingStatus === "UnsubscriptionPending"
                        ).length > 0
                    );
                }}
            />
             <Modal
                onClose={() => setOpenAccountPropertyModal(false)}
                isOpen={openAccountPropertyModal}
                size="md"
                dataMetrics="account-proprty-modal"
            >
                <ModalHeader
                    title={translate(
                        "resources.accounts.modal.AccountPropertyDetails"
                    )}
                    dataMetrics="account-property-modalHeader"
                    enableClose={true}
                />
                <ModalBody dataMetrics="account-property-modalBody">
                    {accountPropertyDetails}
                    <CircularLoader loading={loading} />
                </ModalBody>
                <ModalFooter>
                    <ModalCloseButton>Close</ModalCloseButton>
                </ModalFooter>
            </Modal>
            {openManageXAccountRoleModal && manageAccountModal}
        </>
    );
};

export default withRouter(AccountsList);
