import { stringify } from 'query-string';
import { fetchJson } from './utils/fetch';
import * as FwaasDataProvider from './api/FwaasDataProvider';
import { httpClient } from './httpClient';
import { ApplicationConfigManager, DataProvider } from './types';

const resourceApiResourceMapping = {
  "users": "mgmt/userrolemappings",
  "firewalls": "config/ngfirewalls",
  "register": "subscription",
  "accounts": "mgmt/linkaccounts",
  "xaccountroles": "mgmt/xaccountroles"
}

const resourceListParamMapping = {
  "users": "UserNames",
  "firewalls": "Firewalls",
  "register": "subscription",
  "accounts": "AccountIds"
}

const getOneMapping = {
  "ruleStacks": "RuleStackName"
}

/*
 * Factory for Fwaas resources
 */
const fwaasResourceFactory = new FwaasDataProvider.FwaasResourceFactory();

/**
 * @example
 * getList     => GET http://my.api.url/resources?sort=['title','ASC']&range=[0, 24]
 * getOne      => GET http://my.api.url/resources/123
 * getMany     => GET http://my.api.url/resources?filter={id:[123,456,789]}
 * update      => PUT http://my.api.url/resources/123
 * create      => POST http://my.api.url/resources
 * delete      => DELETE http://my.api.url/resources/123
 *
 */

const PANRestProvider = (
  httpClient = fetchJson
  //countHeader: string = 'Content-Range'
): DataProvider => ({

  getList: (resource, params) => {
    if (resource === "users" ||
      resource === "accounts" ||
      resource === "firewalls" ||
      resource === "ruleStacks" ||
      resource === "feed" ||
      resource === "rules" ||
      resource === "prefix" ||
      resource === "category" ||
      resource === "fqdn" ||
      resource === "certificate" ||
      resource === "fileBlocking" ||
      resource === "predefinedUrlCategory" ||
      resource === "tokens" ||
      resource === "support" ||
      resource === "applications" ||
      resource === "xaccountroles" ||
      resource === "vpcs" ||
      resource === "vpcTags" ||
      resource === "vpcGroups" ||
      resource === "vpcTagIPs" ||
      resource === "vpcPrefixTags") {
        return fwaasResourceFactory.listDetail(resource, params?.data)
        .then((response) => {
          if (response.error) {
            /*
             * Ideally, the caller should handle the reject and leverage/display
             * the error in a user friendly format. This is not happening currently,
             * hence sending empty response.
             */

            return Promise.reject({
              ...response
            });

            /* return Promise.resolve({
              data: [],
              total: 0
            }); */
          } else {
            return Promise.resolve({
              data: response.data || [],
              total: (response.data) ? response.data.length : 0,
              nextToken: response?.nextToken,
              timestamp: response.timestamp
            });
          }
        });
    }

    const resourceApiUrlMapping = {
      "firewalls": ApplicationConfigManager.getInstance().getAPIBaseUri()
    }

    //@ts-ignore
    const resourceApiUrl = (resourceApiResourceMapping[resource]) ? resourceApiResourceMapping[resource] : resource;
    //@ts-ignore
    const resourceUrlDomain = (resourceApiUrlMapping[resource]) ? resourceApiUrlMapping[resource] : ApplicationConfigManager.getInstance().getAPIBaseUri();
    const url = `${resourceUrlDomain}/${resourceApiUrl}`;

    return httpClient(url).then(async ({ headers, json }) => {

      //@ts-ignore
      var keyword = resourceListParamMapping[resource];
      var responseData = json?.Response[keyword];
      if (!json?.ResponseStatus?.ErrorCode && responseData) {


        //@ts-ignore
        var listData: any = [];
        await Promise.all(
          responseData.map(async (element: any) =>
            (resource === "firewalls") ?
              httpClient(`${url}/${element['FirewallName']}`)
              :
              httpClient(`${url}/${element}`)

          )
        ).then((response) => {
          response.map((user: any, index: number) => {
            let describeResourceData = user?.json?.Response;
            describeResourceData['id'] = index;
            listData.push(describeResourceData);
          })
        })

      }

      return {
        data: listData,
        total: listData.length
      };
    }).catch((err) => {
      return {
        data: [],
        total: 0
      }
    });
  },

  getOne: (resource, params) => {
    //@ts-ignore
    const resourceApiUrl = (resourceApiResourceMapping[resource]) ? resourceApiResourceMapping[resource] : resource;
    //@ts-ignore
    const resourceUrlDomain = (resourceApiUrlMapping[resource]) ? resourceApiUrlMapping[resource] : ApplicationConfigManager.getInstance().getAPIBaseUri();
    //@ts-ignore
    const id = params[getOneMapping[resource]] || params.id;
    return httpClient(`${resourceUrlDomain}/${resourceApiUrl}/${id}`).then(({ json }) => ({
      data: json
    }))
  },

  getMany: (resource, params) => {
    //@ts-ignore
    const resourceApiUrl = (resourceApiResourceMapping[resource]) ? resourceApiResourceMapping[resource] : resource;
    //@ts-ignore
    const resourceUrlDomain = (resourceApiUrlMapping[resource]) ? resourceApiUrlMapping[resource] : ApplicationConfigManager.getInstance().getAPIBaseUri();
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${resourceUrlDomain}/${resourceApiUrl}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => ({ data: json }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const rangeStart = (page - 1) * perPage;
    const rangeEnd = page * perPage - 1;

    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${ApplicationConfigManager.getInstance().getAPIBaseUri()}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => {
      return {
        data: json,
        total: 0
      };
    });
  },

  update: (resource, params) => {
    if (resource === "users" ||
        resource === "accounts" ||
        resource === "firewalls" ||
        resource === "ruleStacks" ||
        resource === "feed" ||
        resource === "category" ||
        resource === "predefinedUrlCategory" ||
        resource === "prefix" ||
        resource === "fqdn" ||
        resource === "certificate" ||
        resource === "rules" ||
        resource === "logProfile" ||
        resource === "tokens" ||
        resource === "support" ||
        resource === "tags" ||
        resource === "fileBlocking" ||
        resource === "settings" ||
        resource === "xaccountroles" ||
        resource === "vpcs" ||
        resource === "vpcGroups" ||
        resource === "dlpLinks") {
      return fwaasResourceFactory.update(resource, params)
        .then((response) => {
          if (response.error) {
            return Promise.reject({
              error: response.error
            });
          } else {
            return Promise.resolve({
              data: response.data
            });
          }
        });
    }

    return httpClient(`${ApplicationConfigManager.getInstance().getAPIBaseUri()}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }))
  },

  // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
  updateMany: (resource, params) =>
    Promise.all(
      params.ids.map(async (id) =>
        httpClient(`${ApplicationConfigManager.getInstance().getAPIBaseUri()}/${resource}/${id}`, {
          method: 'PUT',
          body: JSON.stringify(params.data),
        })
      )
    ).then(responses => ({ data: responses.map(({ json }) => json.id) })),

  create: (resource, params) => {
    if (resource === "users" ||
        resource === "accounts" ||
        resource === "firewalls" ||
        resource === "ruleStacks" ||
        resource === "feed" ||
        resource === "rules" ||
        resource === "prefix" ||
        resource === "fqdn" ||
        resource === "certificate" ||
        resource === "category" ||
        resource === "subscription" ||
        resource === "vpcGroups" ) {
      return fwaasResourceFactory.create(resource, params.data)
        .then((response) => {
          if (response.error) {
            return Promise.reject({
              error: response.error
            });
          } else {
            return Promise.resolve({
              data: response.data
            });
          }
        });
    }

    //@ts-ignore
    const resourceApiUrl = (resourceApiResourceMapping[resource]) ? resourceApiResourceMapping[resource] : resource;
    //@ts-ignore
    const resourceUrlDomain = (resourceApiUrlMapping[resource]) ? resourceApiUrlMapping[resource] : ApplicationConfigManager.getInstance().getAPIBaseUri();
    return httpClient(`${resourceUrlDomain}/${resourceApiUrl}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id, json },
    }))
  },

  delete: (resource, params) => {
    if (resource === "users" || resource === "accounts" || resource === "firewalls"
      || resource === "ruleStacks" || resource === "feed" || resource === "prefix"
      || resource === "rules" || resource === "fqdn" || resource === "tags"
      || resource === "certificate" || resource === "category" || resource === "users"  || resource === "vpcGroups") {
      if (resource === "ruleStacks") {
        var customParams = { "RuleStackName": params.id };
      } else if (resource === "tags"){
        //@ts-ignore
        customParams = params;
      } else if (resource === "vpcGroups"){
        //@ts-ignore
        customParams = params;
      }
      else {
        //@ts-ignore
        customParams = params?.previousData;
      }
      return fwaasResourceFactory.delete(resource, customParams)
        .then((response) => {
          if (response.error) {
            return Promise.reject({
              error: response.error
            });
          } else {
            return Promise.resolve({
              data: response.data
            });
          }
        });
    }

    return httpClient(`${ApplicationConfigManager.getInstance().getAPIBaseUri()}/${resource}/${params.id}`, {
      method: 'DELETE',
      headers: new Headers({
        'Content-Type': 'text/plain',
      }),
    }).then(({ json }) => ({ data: json }))
  },

  // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
  deleteMany: (resource, params) =>
    Promise.all(
      params.ids.map(id =>
        httpClient(`${ApplicationConfigManager.getInstance().getAPIBaseUri()}/${resource}/${id}`, {
          method: 'DELETE',
          headers: new Headers({
            'Content-Type': 'text/plain',
          }),
        })
      )
    ).then(responses => ({
      data: responses.map(({ json }) => json.id),
    })),

  describe: (resource: string, url: string, payload = {}) => {
    if (resource === "xaccountroles" || resource === "firewalls" || resource === "ruleStacks"
      || resource === "rules" || resource === "prefix" || resource === "fqdn"
      || resource === "category" || resource === "feed" || resource === "certificate"
      || resource === "users"|| resource === "support" || resource === "counters"
      || resource === "settings" || resource === "panorama" || resource === "regions"
      || resource === "dgs" || resource === "billing" || resource === "cloudManager" || resource === "integrations"
    ) {
      return fwaasResourceFactory.describe(resource, payload)
        //@ts-ignore
        .then((response) => {
          if (response.error) {
            return Promise.resolve({
              error: response.error
            });
          } else {
            return Promise.resolve({
              data: response.data,
              total: (response.data as Object[]).length
            });
          }
        });
    }

    // //@ts-ignore
    // const resourceApiUrl = (resourceApiResourceMapping[resource]) ? resourceApiResourceMapping[resource] : resource;
    // //@ts-ignore
    // const resourceUrlDomain = (resourceApiUrlMapping[resource]) ? resourceApiUrlMapping[resource] : ApplicationConfigManager.getInstance().getAPIBaseUri();
    // return httpClient(`${resourceUrlDomain}/${resourceApiUrl ? resourceApiUrl + '/' : ''}${url}`, payload).then(({ json }) => ({
    //   data: json
    // }))
  },
  refresh: (resource: string, url: string, payload = {}) => {
    if (resource === "counters") {
      return fwaasResourceFactory.refresh(resource, payload)
        //@ts-ignore
        .then((response) => {
          if (response.error) {
            return Promise.resolve({
              error: response.error
            });
          } else {
            return Promise.resolve({
              data: response.data,
              total: (response.data as Object[]).length
            });
          }
        });
    }
  },

  reset: (resource: string, url: string, payload = {}) => {
    if (resource === "counters") {
      return fwaasResourceFactory.reset(resource, payload)
        //@ts-ignore
        .then((response) => {
          if (response.error) {
            return Promise.resolve({
              error: response.error
            });
          } else {
            return Promise.resolve({
              data: response.data,
              total: (response.data as Object[]).length
            });
          }
        });
    }
  },

  get: (resource: string, params: any) => {
    return fwaasResourceFactory.list(resource, params?.data)
      //@ts-ignore
      .then((response) => {
        if (response.error) {
          return Promise.resolve({
            error: response.error
          });
        } else {
          return Promise.resolve({
            data : response.data || [],
            total: (response.data as Object[]).length,
            nextToken: response.nextToken || undefined
          });
        }
      }).catch((err) => {
        return {
          data: [],
        }
      });;
  },

  getXml: (resource:string, params: any) => {
    return fwaasResourceFactory.getXml(resource, params)
      //@ts-ignore
      //@ts-ignore
      .then((response) => {
        if (response.error) {
          return Promise.resolve({
            error: response.error
          });
        } else {
          return Promise.resolve({
            data: response.data,
            total: (response.data as Object[]).length
          });
        }
      });
  },

  commit: (resource: string, params: any) => {
    return fwaasResourceFactory.commit(resource, params)
      //@ts-ignore
      .then((response) => {
        if (response.error) {
          return Promise.resolve({
            error: response.error
          });
        } else {
          return Promise.resolve({
            data: response.data,
            total: (response.data as Object[]).length
          });
        }
      });
  },

  validate: (resource: string, params: any) => {
    return fwaasResourceFactory.validate(resource, params)
      //@ts-ignore
      .then((response) => {
        if (response.error) {
          return Promise.resolve({
            error: response.error
          });
        } else {
          return Promise.resolve({
            data: response.data,
            total: (response.data as Object[]).length
          });
        }
      });
  },

  revert: (resource: string, params: any) => {
    return fwaasResourceFactory.revert(resource, params)
      //@ts-ignore
      .then((response) => {
        if (response.error) {
          return Promise.resolve({
            error: response.error
          });
        } else {
          return Promise.resolve({
            data: response.data,
            total: (response.data as Object[]).length
          });
        }
      });
  },
  commitstatus: (resource: string, params: any) => {
    return fwaasResourceFactory.commitstatus(resource, params)
      //@ts-ignore
      .then((response) => {
        if (response.error) {
          return Promise.resolve({
            error: response.error
          });
        } else {
          return Promise.resolve({
            data: response.data,
            total: (response.data as Object[]).length
          });
        }
      });
  },


      abort: (resource: string) => {
        return fwaasResourceFactory.abort(resource);
      }

});

export const dataProvider = PANRestProvider(httpClient);
