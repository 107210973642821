import { useEffect, useState } from "react";
import { kebabCase } from 'lodash';
import { usePermissions, useTranslate } from "../../customHooks";
import { makeStyles } from '@material-ui/core/styles';
import { PANTitle, toast } from "../../components";
import { withRouter } from "react-router-dom";
import { dataProvider } from "../../dataProvider";
import * as DataTypes from "../../api/FwaasDataTypes";
import { PANWDSTable, PANWDSTooltip } from "../../components/PANWDSElements";
import _ from "lodash";
import { getUsageFilters } from "./utils/usageFilters";
import { getMonthsInCSTFormat, getDateFormat } from "./utils/generateDateUtils";
import { CircleCheckIcon } from '@panwds/icons';
import { Link } from "@panwds/react-ui";

const useStyles = makeStyles((theme) => ({
    appColumnStyles: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100px'
    },
    checkIcon: {
      color: "#33CCB8",
    },
}));

export const UsageExplorer = (props: any) => {
    const translate = useTranslate();
    const classes = useStyles();
    const { permissions } = usePermissions();
    const d = new Date();
    const year = d.getFullYear();
    let month = d.getMonth() + 1; // Months start at 0!
    let day = d.getDate();
    let startDate = getMonthsInCSTFormat(new Date(), -6);
    let endDate = `${year}-${month}-${day}`;
    const [ usageData, setUsageData ] = useState<any>([]);
    const [ loadingCreditUsage, setLoadingCreditUsage ] = useState<boolean>(true);
    const [usageFilters, setUsageFilters] =  useState<any>(getUsageFilters([]));
    const [purchasedCredits, setPurchasedCredits] = useState(0);
    const [freeTrial, setFreeTrial] = useState(false);

    const processBillingInfo = (billingInfo: any[]) => {
      let freeTrial = false;
      let saasContractActive = false;
      let paygActive =  false;
      let totalCredit = 0;

      // if we have saas contract active and a valid payg
      billingInfo.map((info: any) => {
        if (info.Type === "FreeTrial") {
          freeTrial = info.Status === "Active";
          if(freeTrial) {
            setFreeTrial(true);
            totalCredit = info.Credit;
          }
        }
        if (info.Type === "Contract") {
          saasContractActive = info.Status === "Active";
          if(saasContractActive) {
            totalCredit = info.Credit;
          }
        }
        if (info.Type === "PAYG") {
          paygActive = info.Status === "Active";
          if(paygActive) {
            totalCredit = info.Credit;
          }
        }
      });
      return {freeTrial, saasContractActive, totalCredit}
    }

    useEffect(() => {
        dataProvider.describe("billing", '', { startDate: startDate, endDate: endDate })
        .then(async (response: DataTypes.IFwaasApiResponse) => {
            if (response.data){
                let activeBillingType = processBillingInfo(response.data.BillingInfo);
                let totalPurchasedCredits = activeBillingType.totalCredit;
                if(activeBillingType.saasContractActive || activeBillingType.freeTrial) {
                  let creditData = response.data.CreditUsageInfo;
                  creditData.sort((a: any, b: any) => {
                        return (b.Date).localeCompare((a.Date))
                  });
                  setUsageData(creditData);
                  setPurchasedCredits(totalPurchasedCredits);
                  setUsageFilters(getUsageFilters([ ...new Set(creditData?.map((ele: any) => ele.Dimension))]))
                } else {
                    let mergedTableResults = response.data.CreditUsageInfo.concat(response.data.UnitUsageInfo);
                    mergedTableResults.sort((a: any, b: any) => {
                        return (b.Date).localeCompare((a.Date))
                    })
                    setUsageData(mergedTableResults);
                    setPurchasedCredits(totalPurchasedCredits);
                    setUsageFilters(getUsageFilters([ ...new Set(mergedTableResults?.map((ele: any) => ele.Dimension))]))
                }
            }
            else {
              setUsageData([]);
              toast.error(response?.error, { toastId: `usage-history-error` });
            }
        })
        .catch((error: any) => {
          if (error?.noToast) {
            return;
          }
          setUsageData([]);
          toast.error(error?.error, { toastId: `usage-history-error` });
        }).finally(() => {
          setLoadingCreditUsage(false);
        });
       return;
    }, []);

    const loadDateFilter = (dateRange: any) => {
      let clonedUsageData = _.clone(usageData);
      const d = new Date();
      const year = d.getFullYear();
      let month = d.getMonth() + 1; // Months start at 0!
      let day = d.getDate();
      let startDate = '';
      let endDate = '';
      if(dateRange === '1') {
        startDate = getMonthsInCSTFormat(new Date(), -1);
        endDate = `${year}-${month}-${day}`;
      } else if(dateRange === '3') {
        startDate = getMonthsInCSTFormat(new Date(), -3);
        endDate = `${year}-${month}-${day}`;
      } else if(dateRange === '6') {
        startDate = getMonthsInCSTFormat(new Date(), -6);
        endDate = `${year}-${month}-${day}`;
      }
      return clonedUsageData.filter((data: any) => new Date(data?.Date).getTime() >= new Date(startDate).getTime() && new Date(data?.Date).getTime() <= new Date(endDate).getTime());
    }

    const getUsageColumns = () => [
        {
          accessor: 'Date',
          Header: translate(`resources.usage.period`),
          columnSize: 3,
          render: ({ row }: any) => {
            return (
              <span
                key={`usage-history-record-${kebabCase(row?.original?.Date?.toLowerCase())}`}
                className={classes.appColumnStyles}
                data-test-id={`usage-history-record-${kebabCase(row?.original?.Date?.toLowerCase())}`}
              >
                {getDateFormat(row?.original?.Date)}
              </span>
            )
          }
        },
        {
            accessor: 'Dimension',
            noTooltip: true,
            Header: () => {
                return (
                    <PANWDSTooltip showTooltip={true} label={translate(`resources.usage.dimensionTooltip`)}>
                        <span>
                            {translate(`resources.usage.dimension`)}
                        </span>
                    </PANWDSTooltip>
                )
            },
            columnSize: 3,
            render: ({ row }: any) => {
              return (
                <span
                  key={`usage-history-record-${kebabCase(row?.original?.Dimension?.toLowerCase())}`}
                  className={classes.appColumnStyles}
                  data-test-id={`usage-history-record-${kebabCase(row?.original?.Dimension?.toLowerCase())}`}
                >
                  {row?.original?.Dimension}
                </span>
              )
            }
          },
          {
            accessor: 'UsedUnit',
            columnSize: 3,
            noTooltip: true,
            Header: function HeaderComponent() {
              return (
                  <PANWDSTooltip showTooltip={true} label={translate(`resources.usage.consumedUnitsTooltip`)}>
                      <span className="tw-flex tw-gap-1 tw-justify-between tw-items-center">
                          <span>{translate(`resources.usage.consumedUnits`)}</span>
                          <Link
                              dataMetrics="cloudngfw-overviewScreen-getting-card-onClickOfRulesAndObjects"
                              external
                              href="https://docs.paloaltonetworks.com/cloud-ngfw/aws/cloud-ngfw-on-aws/getting-started-with-cloud-ngfw-for-aws/pricing"
                          >
                          </Link>
                    </span>
                  </PANWDSTooltip>
              )
            },
            render: ({ row }: any) => {
              return (
                <span
                  key={`usage-history-record-${kebabCase(row?.original?.UsedUnit)}`}
                  className={classes.appColumnStyles}
                  data-test-id={`usage-history-record-${kebabCase(row?.original?.UsedUnit)}`}
                >
                  {row?.original?.UsedUnit ? row?.original?.UsedUnit : '-'}
                </span>
              )
            }
          },
          {
            accessor: 'UsedCredit',
            Header: () => {
                return (
                    <PANWDSTooltip showTooltip={true} label={translate(`resources.usage.consumedCreditsTooltip`)}>
                        <span>
                            {translate(`resources.usage.consumedCredits`)}
                        </span>
                    </PANWDSTooltip>
                )
            },
            columnSize: 3,
            noTooltip: true,
            render: ({ row }: any) => {
              return (
                <span
                  key={`usage-history-record-${kebabCase(row?.original?.UsedCredit)}`}
                  className={classes.appColumnStyles}
                  data-test-id={`usage-history-record-${kebabCase(row?.original?.UsedCredit)}`}
                >
                  {row?.original?.UsedCredit > 0 ?
                  <CircleCheckIcon className={classes.checkIcon} size='xs' />
                  : '-'}
                </span>
              )
            }
          },
          //bring back post 2.2.1
          // {
          //   accessor: 'TotalCredit',
          //   noTooltip: true,
          //   Header: () => {
          //       return (
          //           <PANWDSTooltip showTooltip={true} label={translate(`resources.usage.purchasedCreditsTooltip`)}>
          //               <span>
          //                   {translate(`resources.usage.purchasedCredits`)}
          //               </span>
          //           </PANWDSTooltip>
          //       )
          //   },
          //   columnSize: 2,
          //   render: ({ row }: any) => {
          //     return (
          //       <span
          //         key={`usage-history-record-${kebabCase(row?.original?.TotalCredit?.toLowerCase())}`}
          //         className={classes.appColumnStyles}
          //         data-test-id={`usage-history-record-${kebabCase(row?.original?.TotalCredit?.toLowerCase())}`}
          //       >
          //         {row?.original?.TotalCredit}
          //       </span>
          //     )
          //   }
          // }
    ];

    //bring back post 2.2.1
    // const usageToolBarActions: any[] = [];
    // usageToolBarActions.push({
    //     title: translate(`resources.usage.downloadAsCsv`),
    //     dataMetrics: "cloudngfw-users-create-btn",
    //     type: 'downloadCsv',
    //     disabled: usageData?.length === 0
    // });

  return (
    <>
        <PANTitle title={translate("resources.usage.name")} tag={translate("resources.usage.beta")} divider={true} paddingContainer="16px" />
        <PANWDSTable
              permissions={permissions}
              columns={getUsageColumns()}
              gridData={usageData}
              loading={loadingCreditUsage}
              title={translate(`resources.usage.usageHistory`)}
              subtitleInfo={loadingCreditUsage ? undefined : freeTrial ? `${translate(`resources.usage.totalCredits`) + ': ' + purchasedCredits}` :`${translate(`resources.usage.purchasedCredits`) + ': ' + purchasedCredits}`}
              searchFilterRequired={true}
              showToolbar={true}
              idProperty={"usage-history"}
              showTileTitle
              emptySubtitle={translate(`resources.usage.noUsageHistory`)}
              singleSelect={false}
              multiSelect={false}
              dataTestId="usage-history-table"
              tableInnerContainerClassName="usage-history-table"
              infiniteScroll={false}
              enablePagination={true}
              rowSize="standard"
              offsetTableHeight={260}
              // dropDownActionsArray={usageToolBarActions} //bring back post 2.2.1
              filterBar={{
                filterBarRequired: true,
                filterConfig: usageFilters,
              }}
              loadDateFilter={loadDateFilter}
            />
    </>
  );
}

export default withRouter(UsageExplorer);
