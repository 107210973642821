import {AccordionPanel, Link} from "@panwds/react-ui";
import {FormControl, Grid} from "@material-ui/core";
import {Field, FormSpy, useForm, useFormState} from "react-final-form";
import {PANWDSRadioField, PANWDSSelectWithSearch} from "../../../../components/PANWDSElements";
import * as React from "react";
import {useTranslate} from "../../../../customHooks";
import {useEffect, useState} from "react";
import {dataProvider} from "../../../../dataProvider";
import * as DataTypes from "../../../../api/FwaasDataTypes";
import {toast} from "../../../../components";
import {useLocation} from "react-router-dom";
import CircularLoader from "../../../../components/CircularLoader/CircularLoader";
import { FormattedMessage } from "react-intl";

const Index = ({record}: {record: any}) => {
    const translate = useTranslate();
    const formState = useFormState();
    const form = useForm();
    const search = useLocation().search;
    const accountId = new URLSearchParams(search).get('AccountId');
    const [ruleStackChoices, setRuleStackChoices] = useState<any[] | undefined>(undefined);
    const [panoramas, setPanoramas] = useState<any[] | undefined>(undefined);
    const [cloudManagers, setCloudManagers] = useState<any[] | undefined>(undefined);
    const [integrationType, setIntegrationType] = useState('Rulestack');
    const [loading, setLoading] = useState(true);
    const [scmNoLink, setScmNoLink] = useState(false);

    useEffect(() => {

        dataProvider.get("ruleStacks", {
            data: {
                Candidate: false,
                Running: true,
                Scope: "Local",
                AccountId: accountId
            }
        }).then(async (response: DataTypes.IFwaasApiResponse) => {
            //@ts-ignore
            let ret: DataTypes.IFwaasApiResponse = {};
            if (response?.data) {
                //@ts-ignore
                let rulestacks = response.data as string[];
                if (rulestacks) {
                    let choices: any = rulestacks.map((rulestack: any) => ({text: rulestack, value: rulestack}));
                    choices = choices.sort((first: any, second: any) => {
                        return first.value.localeCompare(second.value, undefined, {sensitivity: 'base'});
                    });

                    setRuleStackChoices(choices);
                    return;
                }
            } else {
                toast.error(response?.error, {toastId: "ruleStacks-get"});
            }
            return ret;
        }).catch((e: any) => {
            toast.error(e?.error, {toastId: "ruleStacks-get"});
        });

        let apiCall: { resource: string, url?: string, payload?: any } = {
            resource: "settings",
            payload: {panorama: true}
        }
        if (process.env.REACT_APP_SHOW_CM === "true") {
            apiCall = {
                resource: "integrations",
            }
        }

        dataProvider.describe(apiCall.resource, apiCall.url || "", apiCall?.payload || {})
            .then(async (response: DataTypes.IFwaasApiResponse) => {
                let links = Object.keys(response.data.Links);
                let cmLinks: Array<any> = [];
                let pnLinks: Array<any> = [];
                let iType = 'Rulestack';
                links.map(link => {
                    const value = response.data.Links[link].LinkName ? `${link} (${response.data.Links[link].LinkName})` : link;
                    if (response.data.Links[link]?.hasOwnProperty('CloudManager') && value !== undefined && response.data.Links[link].Status === 'Active') {
                        cmLinks.push({text: value, value: link});
                    }
                    if (response.data.Links[link]?.hasOwnProperty('Panorama') && value !== undefined) {
                        pnLinks.push({text: value, value: link});
                    }
                    if (record.Firewall?.LinkId && record.Firewall?.LinkId === link) {
                        if (response.data.Links[link]?.hasOwnProperty('CloudManager')) {
                            iType = 'Strata';
                        } else if (response.data.Links[link]?.hasOwnProperty('Panorama')) {
                            iType = 'Panorama';

                        }
                    }
                    if(record.Firewall?.LinkId && record.Firewall?.LinkId === "Link-SCM-None") {
                        iType = 'Strata';
                        setScmNoLink(true)
                    }
                });
                setPanoramas(pnLinks);
                setCloudManagers(cmLinks);
                setIntegrationType(iType);
                setFormValues({pnLinks, cmLinks, iType});
                setLoading(false);
            })
            .catch((e: any) => {
                toast.error(e?.error?.error, {toastId: "support-describe"});
                setLoading(false);
            })
    }, [record]);

    const setFormValues = ({pnLinks, cmLinks, iType}: {pnLinks: any, cmLinks: any, iType: string}) => {
        // form.batch(() => {
        //     if (record?.Firewall?.LinkId) form.change('Firewall', {...formState.values.Firewall, ...getLinkId({pnLinks, cmLinks, iType})})
        //     form.change('PolicyManaged', iType)
        //     console.log('done');
        // });
        let initialValue = {...form.getState().values};
        if (record?.Firewall?.LinkId) initialValue = {...initialValue, Firewall: {...formState.values.Firewall, ...getLinkId({pnLinks, cmLinks, iType})}}
        initialValue["PolicyManaged"] = iType;
        form.setConfig("initialValues", initialValue);
    };

    const getLinkId = ({pnLinks, cmLinks, iType}: {pnLinks: any, cmLinks: any, iType: string}) => {
        if(iType === 'Panorama') {
            if(pnLinks) {
                return {"LinkId": pnLinks?.filter((entry: any) => entry?.text?.includes(record?.Firewall?.LinkId))[0]?.text}
            } else {
                return {"LinkId": record?.Firewall?.LinkId}
            }

        } else if(iType === 'Strata') {
            if(cmLinks) {
                if(record.Firewall?.LinkId && record.Firewall?.LinkId === "Link-SCM-None") {
                    return {"CMLinkId": "None", LinkId: null}
                } else {
                    return {"CMLinkId": cmLinks?.filter((entry: any) => entry?.text?.includes(record?.Firewall?.LinkId))[0]?.text, LinkId: null}
                }
            } else {
                return {"CMLinkId": record?.Firewall?.LinkId, LinkId: null}
            }
        }
        return
    }

    const getPolicyManagedList = () => {
        let PolicyManagedList = [
            {label: "Rulestack", value: "Rulestack"},
            {label: translate(`resources.firewalls.fields.Panorama`), value: "Panorama"}
        ];
        if (process.env.REACT_APP_SHOW_CM === "true") {
            PolicyManagedList.splice(1, 0, {
                label: translate(`resources.firewalls.fields.StrataCloudManager`),
                value: "Strata",
                disabled: !record?.Firewall?.LinkId && !record?.Firewall?.RuleStackName
            });
        }

        return PolicyManagedList;
    }

    return (
        <AccordionPanel title={translate(`resources.firewalls.fields.PolicyManagement`)}>

            {loading && <div style={{minHeight: "100px"}}><CircularLoader loading={loading} /></div>}

            {!loading && <>

                <FormControl fullWidth>
                    <Field
                        name="PolicyManaged"
                        row
                        // @ts-ignore
                        component={PANWDSRadioField}
                        options={getPolicyManagedList()}
                        initValue={integrationType}
                        disabled={formState.values?.Firewall?.LinkId || formState.values?.Firewall?.RuleStackName || formState.values?.Firewall?.CMLinkId }
                        dataMetrics={"cloudngfw-firewall-edit-policy-managed"}
                        muted={(formState.values?.Firewall?.LinkId) ? translate(`resources.firewalls.fields.PolicyManagementLinkIdMessage`) :
                            (formState.values?.Firewall?.RuleStackName) ? translate(`resources.firewalls.fields.PolicyManagementRSMessage`) : formState.values?.Firewall?.CMLinkId ?  <FormattedMessage
                            id={"resources.firewalls.fields.PolicyManagementSCMMessage"}
                            values={{
                                break: <br />,
                                link: <Link
                                    dataMetrics="support-request"
                                    external
                                    href="https://support.qa.app.paloaltonetworks.com/Support/Index"
                                >
                                    {translate(`resources.firewalls.fields.CreateANewCase`)}
                                </Link>
                            }} />: ""}
                    />
                </FormControl>
                <FormSpy subscription={{values: true}}>
                    {({values, ...rest}) => {
                        if (values.PolicyManaged === "Rulestack") {
                            return (
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <Field
                                            name="Firewall.RuleStackName"
                                            row
                                            // @ts-ignore
                                            component={PANWDSSelectWithSearch}
                                            title={translate(`resources.firewalls.fields.RuleStackName`)}
                                            items={ruleStackChoices || []}
                                            dataMetrics={"cloudngfw-firewall-edit-rulestack-name"}
                                            allowSetNone={record?.Firewall?.RuleStackName}
                                        />
                                    </FormControl>
                                </Grid>
                            );
                        } else if (values?.PolicyManaged === "Strata") {
                            return (
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <Field
                                            name="Firewall.CMLinkId"
                                            row
                                            // @ts-ignore
                                            component={PANWDSSelectWithSearch}
                                            title={translate(`resources.firewalls.fields.PolicyManager`)}
                                            items={cloudManagers || []}
                                            loading={cloudManagers === undefined}
                                            disabled={cloudManagers === undefined || values?.Firewall?.GlobalRuleStackName}
                                            dataMetrics={"cloudngfw-firewall-edit-link-id"}
                                            muted={translate(`resources.firewalls.fields.LinkedCloudManagerMessage`)}
                                            allowSetNone
                                        />
                                    </FormControl>
                                </Grid>
                            );
                        } else if (values.PolicyManaged === "Panorama") {
                            return (
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <Field
                                            name="Firewall.LinkId"
                                            row
                                            // @ts-ignore
                                            component={PANWDSSelectWithSearch}
                                            title={translate(`resources.firewalls.fields.LinkedPanorama`)}
                                            items={panoramas || []}
                                            loading={panoramas === undefined}
                                            disabled={panoramas === undefined || values?.Firewall?.GlobalRuleStackName}
                                            dataMetrics={"cloudngfw-firewall-create-link-id"}
                                            muted={(values?.Firewall?.GlobalRuleStackName) ? translate(`resources.firewalls.fields.LinkedPanoramaMessage`) : ""}
                                            allowSetNone
                                        />
                                    </FormControl>
                                </Grid>
                            );
                        } else return null;
                    }}
                </FormSpy>

            </>}
        </AccordionPanel>
    );
};

export default Index;
