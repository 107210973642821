import { CircularProgress, FormControl } from "@material-ui/core";
import { createTheme, makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { Body, Bold, Button, Link, ModalBody, ModalHeader, Title } from "@panwds/react-ui";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { Field, FormSpy, withTypes } from "react-final-form";
import { FormattedMessage } from "react-intl";
import * as DataTypes from "../api/FwaasDataTypes";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { SaveButton, Button as SubmitButton, toast } from "../components";
import {
    PANWDSInput,
    PANWDSRadioField,
    PANWDSSelect,
} from "../components/PANWDSElements";
import { PERMISSIONS, ROLES } from "../config/permissions";
import { useTranslate } from "../customHooks";
import { dataProvider } from "../dataProvider";
import logo from "../images/panw_logo.png";
import { ReduxActions } from "../redux";
import { ApplicationConfigManager } from "../types";
import { callCSPRedirectLink } from "../utils";
import { composeValidators, isRequired } from "../utils/validate";
import { AccountDescriptionBig, AccountTitle, AccountTitleBig } from "./styles";
import { lightTheme } from "./themes";
import {useLocation} from "react-router-dom";
import {RouteUri} from "../routeUri";

const useStyles = makeStyles((theme) => ({
    modalHeader: {
        border: "none",
        position: "absolute",
        right: 0
    },
    modalBody: {
        padding: "55px 24px 24px"
    },
    avatar: {
        display: "flex",
        justifyContent: "center",
    },
    form: {
        padding: "0 1em 1em 1em",
        height: "100%",
        width: "100%",
    },
    cspForm: {
        minHeight: "450px",
        display: "flex",
        flexDirection: "column",
    },
    cspFormTitle: {
        textAlign: "center",
        paddingTop: "3rem"
    },
    formControl: {
        marginTop: "7px",
        width: "100%",
    },
    actions: {
        justifyContent: "space-between",
        padding: 0,
        marginTop: 10,
    },
    controlsContainer: {
        padding: "1.5rem 0",
  },
    controlsContainerOld: {
        display: "grid",
        gridTemplateColumns: "110px calc(100% - 110px)",
        "&>div:last-child": {
            paddingTop: "5px",
        },
    },
  controlsContainer2: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "10px",
  },
  initial: {
    height: "200px",
    textAlign: "center",
    "&>div": {
      marginTop: "100px",
    },
  },
  startPageContainer: {
    paddingTop: "2rem",
      flex: 1,
  },
  addressContainer: {
    marginBottom: "20px",
  },
  toolbar: {
    background: "transparent",
    display: "flex",
    gap: theme.spacing(1),
    justifyContent: "end",
    "-webkit-justify-content": "flex-end",
    alignItems: "flex-start",
    boxSizing: "border-box",
  },
}));
interface FormValues {
    SupportAccount?: string;
    SupportAccountId?: string;
    Address?: {
        country?: string;
    };
}

const { Form } = withTypes<FormValues>();

type CspPages = "initial" | "start" | "confirm" | "address" | "finish";

const CSPAccount = (props: any) => {
    const translate = useTranslate();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const reduxState = useAppSelector((state) => state);
    const supportState = reduxState.support;
    const classes = useStyles();
    const [supportAcctChoices, setSupportAcctChoices] = useState<any[] | undefined>([]);
    const [cspPage, setCspPage] = useState<CspPages>("initial");
    const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
      // This is a hack this logic should be change
      if (location.pathname === RouteUri.CspCallback || supportState.PubSubMessageStatus === 'SupportAccountPending') {
          handleFinish();
      } else {
          dataProvider
              .describe("support", "", { ExistingSupportAccountsInfo: true })
              .then(async (response: DataTypes.IFwaasApiResponse) => {
                  if (response.data) {
                      if (
                          response.data.SupportInformations &&
                          !isEmpty(response.data.SupportInformations)
                      ) {
                          setSupportAcctChoices(
                              response.data.SupportInformations.map((data: any) => ({
                                  text: data?.SupportName ? `${data?.SupportAccountId} - ${data?.SupportName}` : data?.SupportAccountId,
                                  value: data?.SupportAccountId,
                              }))
                          );
                          setCspPage("start");
                      } else {
                          if (process.env.REACT_APP_SHOW_CSP === "true") {
                              await handleCspNoFlow();
                          } else {
                              setCspPage("address");
                          }
                      }
                  } else {
                      toast.error(response?.error);
                      let newAppConfig = ApplicationConfigManager.getInstance().getConfig();
                      newAppConfig.setCspWorkFlow = false;
                      await ApplicationConfigManager.getInstance().setConfig(newAppConfig);
                      props.setCspWorkflow(false);
                  }
              })
              .catch(async (e: any) => {
                  toast.error(e?.error);
                  let newAppConfig = ApplicationConfigManager.getInstance().getConfig();
                  newAppConfig.setCspWorkFlow = false;
                  await ApplicationConfigManager.getInstance().setConfig(newAppConfig);
                  props.setCspWorkflow(false);
              });
      }
  }, []);

    const handleCspNoFlow = async () => {
        setCspPage("finish");
        dispatch(ReduxActions.setCspEnable(false));
        let newAppConfig = ApplicationConfigManager.getInstance().getConfig();
        // Add permissions
        const uniquePermissions = [...Array.from(new Set<string>(PERMISSIONS[ROLES.SupportReader].flat()))];
        const perm = Object.fromEntries(uniquePermissions.map((permission => [permission, false])));
        if (!isEmpty(newAppConfig.permissions)) {
            newAppConfig.permissions = {...newAppConfig.permissions, ...perm}
        } else {
            newAppConfig.permissions = perm;
        }
        await ApplicationConfigManager.getInstance().setConfig(newAppConfig);
        return;
    };

    const handleSubmit = async (values: any, form: any) => {
        let payload: any = {};
        if (cspPage === "start" && values["SupportAccount"] === "yes") {
            if (process.env.REACT_APP_SHOW_CSP === "true") {
                setCspPage("confirm");
                return;
            } else {
                payload["SupportAccountId"] = values["SupportAccountId"];
            }
        } else if (cspPage === "confirm" && values["SupportAccount"] === "yes") {
            payload["SupportAccountId"] = values["SupportAccountId"];
        } else {
            if (cspPage === "start") {
                if (process.env.REACT_APP_SHOW_CSP === "true") {
                    await handleCspNoFlow();
                } else {
                    setCspPage("address");
                }
                return;
            } else if (cspPage === "address") {
                let data = {...values.Address};
                if (data?.street2) {
                    data["street1"] = data["street1"].concat(" ", data["street2"]);
                    delete data["street2"];
                }
                payload["Address"] = JSON.stringify(data);
            }
        }
        setSubmitting(true);

        dataProvider
            .update("support", payload)
            .then(async (response: DataTypes.IFwaasApiResponse) => {
                if (!response.data) {
                    toast.error(response?.error);
                }
            })
            .catch((e: any) => {
                toast.error(e?.error);
            })
            .finally(() => {
                setSubmitting(false);
                setCspPage("finish");
            });
    };

    const handleFinish = () => {
        props.setCspWorkflow(false);
        //history.push(RouteUri.NGFirewallList);
    };

    const getNextDisabled = (values: any) => {
        if (cspPage === "start" && !values.SupportAccount) {
            return true;
        }
        if (cspPage === "confirm") {
            return false;
        }
        return values.SupportAccount === "yes" && !values.SupportAccountId
    }

    const Toolbar = (toolbarProps: any) => {
        const { submitting, pristine, values } = toolbarProps;
        const disableNext = getNextDisabled(values);

    return cspPage !== "initial" ? (
      <div className={classes.toolbar}>
          {process.env.REACT_APP_SHOW_CSP === "true" ? (
              <>
                  {(cspPage === "confirm" || cspPage === "finish") && (
                      <Button
                          appearance="secondary"
                          onClick={() => cspPage === "confirm" ? setCspPage("start") : handleFinish()}
                          size="md"
                          data-metrics="cloudngfw-csp-confirm-back-button"
                      >{cspPage === "confirm" ? translate("common.back") : translate("common.close")}</Button>
                  )}
                  {cspPage !== "finish" && (
                      <SaveButton
                          appearance="primary"
                          size="md"
                          label={cspPage === "start" ? translate("common.next") : translate("common.confirm")}
                          submitOnEnter={true}
                          loading={submitting}
                          disabled={submitting || disableNext}
                          dataMetrics="cloudngfw-csp-next-button"
                      />
                  )}
              </>
          ) : (
              <>
              {cspPage !== "finish" && (
                  <SubmitButton
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="large"
                      disabled={submitting || pristine || disableNext}
                      label={"cspOld.btnNext"}
                      loading={submitting}
                      data-metrics="cloudngfw-csp-next-button"
                  />
              )}
              {cspPage === "finish" && (
                  <SubmitButton
                      variant="contained"
                      size="large"
                      color="secondary"
                      label={"cspOld.btnClose"}
                      onClick={() => handleFinish()}
                      data-metrics="cloudngfw-csp-close-button"
                  />
              )}
              </>
          )}
      </div>
    ) : (
      <></>
    );
  };

    const validate = (values: FormValues) => {
        const errors: FormValues = {};
        if (cspPage === "start") {
            if (!values.SupportAccount) {
                errors.SupportAccount = translate("validation.required");
            }
            if (values.SupportAccount === "yes" && !values.SupportAccountId) {
                errors.SupportAccountId = translate("validation.required");
            }
        } else if (cspPage === "address") {
            if (!values.Address?.country) {
                // @ts-ignore
                errors["Address.country"] = translate("validation.required");
            }
        }
        return errors;
    };

  const cspOldForm = (formProps: any) => {
      let { handleSubmit, values } = formProps;
      return (
          <form onSubmit={handleSubmit} className={classes.cspForm}>
              <div className={classes.avatar}>
                  <img src={logo} alt="logo" />
              </div>
              {cspPage !== "finish" && (
                  <AccountTitle>{translate("cspOld.title")}</AccountTitle>
              )}
              {cspPage === "finish" && (
                  <AccountTitleBig>{translate("cspOld.titleEnd")}</AccountTitleBig>
              )}

              {cspPage === "initial" && (
                  <div className={classes.initial}>
                      <CircularProgress size={50} thickness={2} />
                  </div>
              )}
              {cspPage === "start" && (
                  <div className={classes.startPageContainer}>
                      <span>{translate("cspOld.startDetail")}</span>
                      <div className={classes.controlsContainerOld}>
                          <FormControl className={classes.formControl}>
                              <Field
                                  name="SupportAccount"
                                  // @ts-ignore
                                  component={PANWDSRadioField}
                                  row
                                  options={[
                                      { label: "Yes", value: "yes" },
                                      { label: "No", value: "no" },
                                  ]}
                                  required
                              />
                          </FormControl>
                          <FormSpy subscription={{ values: true }}>
                              {({ values }) => {
                                  const disableProp = {disabled: values?.SupportAccount !== "yes"};
                                  return <FormControl className={classes.formControl}>
                                      <Field
                                          name="SupportAccountId"
                                          // @ts-ignore
                                          component={PANWDSSelect}
                                          items={supportAcctChoices}
                                          {...disableProp}
                                      />
                                  </FormControl>
                              }}
                          </FormSpy>
                      </div>
                  </div>
              )}
              {cspPage === "address" && (
                  <div className={classes.addressContainer}>
                      <FormControl className={classes.formControl}>
                          <Field
                              name="Address.company_name"
                              // @ts-ignore
                              component={PANWDSInput}
                              title={translate("cspOld.companyName")}
                              required
                              validate={composeValidators(isRequired)}
                          />
                      </FormControl>
                      <strong>{translate("cspOld.address")}</strong>
                      <FormControl className={classes.formControl}>
                          <Field
                              name="Address.country"
                              // @ts-ignore
                              //component={PANSelectField}
                              component={PANWDSInput}
                              required
                              validate={composeValidators(isRequired)}
                              title={translate("cspOld.country")}
                          />
                      </FormControl>
                      <FormControl className={classes.formControl}>
                          <Field
                              name="Address.street1"
                              // @ts-ignore
                              component={PANWDSInput}
                              title={translate("cspOld.addressOne")}
                              muted={translate("cspOld.addressOneSub")}
                              required
                              validate={composeValidators(isRequired)}
                          />
                      </FormControl>
                      <FormControl className={classes.formControl}>
                          <Field
                              name="Address.street2"
                              // @ts-ignore
                              component={PANWDSInput}
                              title={translate("cspOld.addressTwo")}
                              muted={translate("cspOld.addressTwoSub")}
                          />
                      </FormControl>
                      <FormControl className={classes.formControl}>
                          <Field
                              name="Address.city"
                              // @ts-ignore
                              component={PANWDSInput}
                              title={translate("cspOld.city")}
                              required
                              validate={composeValidators(isRequired)}
                          />
                      </FormControl>
                      <div className={classes.controlsContainer2}>
                          <FormControl className={classes.formControl}>
                              <Field
                                  name="Address.state"
                                  // @ts-ignore
                                  //component={PANSelectField}
                                  component={PANWDSInput}
                                  title={translate("cspOld.state")}
                                  required
                                  validate={composeValidators(isRequired)}
                              />
                          </FormControl>
                          <FormControl className={classes.formControl}>
                              <Field
                                  name="Address.zip"
                                  // @ts-ignore
                                  component={PANWDSInput}
                                  title={translate("cspOld.zipCode")}
                                  required
                                  validate={composeValidators(isRequired)}
                              />
                          </FormControl>
                      </div>
                  </div>
              )}
              {cspPage === "finish" && (
                  <AccountDescriptionBig>
                      {translate("cspOld.descriptionFinish")}
                  </AccountDescriptionBig>
              )}
              <Toolbar {...formProps} submitting={submitting} />
          </form>
      );
  }
  const cspForm = (formProps: any) => {
      let { handleSubmit, values } = formProps;
      return (
          <form onSubmit={handleSubmit} className={classes.cspForm}>
              <div className={classes.avatar}>
                  <img src={logo} alt="logo" />
              </div>
              <Title level={1} size={cspPage === "finish" ? "xs" : "xxs"} addClassName={classes.cspFormTitle}>
                  {cspPage !== "finish" && translate("csp.title")}
                  {cspPage === "finish" && translate("csp.titleCongrats")}
              </Title>
              {cspPage === "initial" && (
                  <div className={classes.initial}>
                      <CircularProgress size={50} thickness={2} />
                  </div>
              )}
              <div className={classes.startPageContainer}>
                  <Body>
                      {cspPage === "start" && translate("csp.startDetail")}
                      {cspPage === "confirm" && translate("csp.confirmDetail")}
                      {cspPage === "finish" && <Bold>{translate("csp.descriptionDetail")}</Bold>}
                  </Body>
                  {cspPage === "start" && (
                      <div className={classes.controlsContainer}>
                          <div className="tw-flex tw-items-center">
                              <FormControl>
                                  <Field
                                      name="SupportAccount"
                                      // @ts-ignore
                                      component={PANWDSRadioField}
                                      row
                                      options={[
                                          { label: "Yes", value: "yes" },
                                      ]}
                                      disabled={isEmpty(supportAcctChoices)}
                                      required
                                  />
                              </FormControl>
                              <FormSpy subscription={{ values: true }}>
                                  {({ values }) => {
                                      const disableProp = {disabled: values?.SupportAccount !== "yes"};
                                      return <FormControl className="tw-flex-1">
                                          <Field
                                              name="SupportAccountId"
                                              // @ts-ignore
                                              component={PANWDSSelect}
                                              items={supportAcctChoices}
                                              {...disableProp}
                                          />
                                      </FormControl>
                                  }}
                              </FormSpy>
                          </div>
                          <FormControl className={classes.formControl}>
                              <Field
                                  name="SupportAccount"
                                  // @ts-ignore
                                  component={PANWDSRadioField}
                                  row
                                  options={[
                                      { label: "No, I will register for support later.", value: "no" },
                                  ]}
                                  required
                              />
                          </FormControl>
                      </div>
                  )}
                  {cspPage === "finish" && (
                      <div className="tw-pt-4">
                          <Body>
                              {/* @ts-expect-error Server Component */}
                              <FormattedMessage
                                  id={values.SupportAccount === "yes" ? "csp.descriptionFinish" : "csp.descriptionFinishWithNo"}
                                  values={{
                                      descriptionPremium: <Body as={"span"} appearance={"link"}>{translate("csp.descriptionPremium")}</Body>
                                  }}/>
                          </Body>

                          {values.SupportAccount !== "yes" && <Link size={"md"}
                                                                  addClassName="tw-pt-4 tw-absolute tw-cursor-pointer"
                                                                  external
                                                                  dataMetrics={"cloudngfw-csp-redirect"}
                                                                  onClick={() => callCSPRedirectLink({supportState, translate})}>
                              {translate("csp.registerSupportAccount")}
                          </Link>}
                      </div>
                  )}
              </div>
              <Toolbar {...formProps} submitting={submitting} />
          </form>
      );
  }

  return (
      <>
          <ModalHeader enableClose={cspPage === "finish"}  addClassName={classes.modalHeader}/>
          <ModalBody addClassName={classes.modalBody}>
              <Form
                  onSubmit={handleSubmit}
                  validate={validate}
                  render={(formProps) => {
                      if (process.env.REACT_APP_SHOW_CSP === "true") {
                          return cspForm(formProps)
                      } else {
                          return cspOldForm(formProps)
                      }
                  }}
              />
          </ModalBody>
      </>
  );
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const CSPAccountWithTheme = (props: any) => (
    <ThemeProvider theme={createTheme(lightTheme)}>
        <CSPAccount {...props} />
    </ThemeProvider>
);

export default CSPAccountWithTheme;
